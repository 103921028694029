import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import axios from "axios";
import CONST from '../../common/constants';
import {showLoader,showToaster} from '../../../middleware/actions';
const OtherSettings = () => {
  const dispatch = useDispatch();
  let flashMsgCalled = false;
  const [flashMsgObj, setFlashMsgObj] = useState({ flashMsgId: '', flashText: '', isOnHomePage: 0, isOnCheckoutPage: 0 });
  const fetchFlashMsg = () => {
    dispatch(showLoader(true));
    axios.post(`${CONST.apiBase}dashboard.php`, {
      type: 'getFlashMsgData'
    }).then((flashMsgData) => {
      if (flashMsgData?.data?.status === 'success') {
        let temp = {
          flashMsgId: flashMsgData?.data?.flashMsgId,
          flashText: flashMsgData?.data?.flashText,
          isOnHomePage: flashMsgData?.data?.isOnHomePage,
          isOnCheckoutPage: flashMsgData?.data?.isOnCheckoutPage
        };
        setFlashMsgObj(temp);
      } else {
        console.log('flash msg not available.');
      }
    }).catch((err) => {
      console.log('err:', err);
    }).finally(()=>{
      dispatch(showLoader(false));
    });
  }
  useEffect(() => {
    if (!flashMsgCalled) {
      flashMsgCalled = true;
      fetchFlashMsg();
    }
  }, [flashMsgCalled]);

  const flashMsgFieldChanged = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let temp = { ...flashMsgObj };
    temp[name] = value;
    setFlashMsgObj(temp);
  }
  const saveFlashMessage = () => {
    dispatch(showLoader(true));
    const payload = { type: 'saveFlashMsgData', ...flashMsgObj };
    axios.post(`${CONST.apiBase}dashboard.php`, payload).then((response) => {
      if (response?.data?.status === 'success') {
        dispatch(showToaster({show:true,type:'success',msg:'Flash Message Updated Successfully.'}));
        console.log('success....');
      } else {
        console.log('err....');
      }
    }).catch((err)=>{
      console.log(err);
    }).finally(()=>{
      dispatch(showLoader(false));
    });
  }
  return (
    <div className="other-settings-container">
      <div className="flash-message">
        <section className="bg-teal-50">
          <div className="flex justify-center items-center">
            <div className="container mx-auto px-2 md:px-4 lg:px-20 justify-center items-center">
              <div className="w-full p-4 my-4 mx-auto lg:w-9/12 rounded-2xl shadow-2xl bg-teal-200">
                <div className='user-account-details m-2 md:m-4 p-2 md:p-4 border border-teal-800 rounded'>
                  <div className="flex">
                    <h2 className="mt-2">
                      <span className="font-bold uppercase lg:text-2xl text-base">Add/Update Flash Message</span>
                    </h2>
                  </div>
                  <div className="grid grid-cols-1 gap-5 mt-5">
                    <div>
                      <label className="text-slate-500 font-bold float-left">Flash Message Text</label>
                      <textarea
                        className={`w-full text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline text-gray-900`}
                        name="flashText"
                        required
                        value={flashMsgObj.flashText}
                        onChange={flashMsgFieldChanged}
                        type="text" placeholder="Flash Message Text"></textarea>
                    </div>
                  </div>
                  <div className="my-4 w-full flex">
                    <button
                      className="transition ease-in duration-300 text-sm font-medium mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600"
                      onClick={saveFlashMessage}
                    >
                      <span>Update</span>
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default OtherSettings;