import { Outlet, Navigate } from 'react-router-dom'
import axios from "axios";
import CONST from '../components/common/constants';

const PrivateAdminRoutes = () => {
    let auth = {'token': false};
    const adminInfo = JSON.parse(sessionStorage.getItem('adminData')) || {};
    if(adminInfo && adminInfo.guid && adminInfo.guid.length === 36){
        auth.token = true;
    }
    return(
        auth.token ? <Outlet/> : <Navigate to="/db-login"/>
    )
}

export default PrivateAdminRoutes;