const Logo = () => {
    return (
      <div className="items-center flex-shrink-0 text-white mr-2 md:mr-6">
        <div className="inline-block">
          <a href="/" className="flex items-center justify-center">
            <img src="mp-default-logo.png" className="h-16" alt="mahabooks Logo" />
          </a>
        </div>
        <div className="inline-block align-top mt-1">
          <div className="block">
            <span className="ml-1 uppercase font-black text-xl md:text-2xl">Mahabooks</span>
          </div>
          <div className="block">
            <span className="font-black text-sm">जिद्ध तुमची, पुस्तके आमची</span>
          </div>
        </div>
      </div>
    );
  }
  export default Logo;
  