import Sidebar from "../sidebar";
import Books from "./books";
import FlashMessage from './flash-message';
const MainView = ({showLoader,setSelBook, showModal}) => {
    return (
        <main className="">
            <FlashMessage/>
            <div className="main max-w-full overflow-x-hidden bg-teal-100" style={{height:'100%', minHeight:'72vh'}}>
                <Books showModal={showModal} setSelBook = {setSelBook}/>
            </div>
        </main>
    )
}
export default MainView;