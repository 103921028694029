
import {useState, useEffect,useCallback} from 'react'; 
import axios from "axios"; 
import { useSelector, useDispatch } from 'react-redux';
import {PencilSquareIcon, ArrowUturnLeftIcon, ArrowUturnRightIcon} from '@heroicons/react/24/outline';
import Logo from '../../common/logo';
import CONST from "../../common/constants";
import BackToHome from '../back-to-home';
import {showLoader} from '../../../middleware/actions';
const ManageAddress = ({book,showModal, opts}) => {
    const disabledClasses = 'cursor-not-allowed'; 
    const enabledClasses = 'text-gray-900'; 
    const dispatch = useDispatch();
    const isLoggedIn = sessionStorage.getItem('isLoggedIn') === 'true';
    const userObj = JSON.parse(sessionStorage.getItem('userData')) || {};
    const shoppingCart = useSelector((state) => state.cart);
    const defAddObj = {
      type:'addUpdateUserAddress',
      id:'',
      userId:userObj.userId,
      flatNo:'',
      village:'',
      taluka:'',
      dist:'',
      state:'',
      pincode:'',
      isDefault:''
    };

    const [addresses, setAddresses] = useState(userObj.addresses||[]);
    const [states, setStates] = useState([]);
    const [disableAccount,setDisableAccount] = useState(true);
    const [stateValue, setStateValue] = useState((userObj.stateId||CONST.defaultStateId));
    const [showAddForm,setShowAddForm] = useState(false);
    const [userData,setUserData] = useState({
      userId:userObj.userId,
      type:'updateUserData',
      mobile:userObj.phone,
      firstName:(userObj.firstName || ''),
      lastName:(userObj.lastName || ''),
      email:(userObj.email || '')
    });

    const [addObj,setAddObj] = useState(defAddObj);
    const getAddresses = useCallback(()=> {
      dispatch(showLoader(true));
      axios
        .post(`${CONST.apiBase}data.php`, {
          type:'getAddresses',
          userId:userObj.userId
        })
        .then((response) => {
          dispatch(showLoader(false));
          if (response.data.status === "success") {
            setAddresses(response.data.addresses);
              const userObj = JSON.parse(sessionStorage.getItem('userData')) || {};
              userObj.hasAddress = '1';
              userObj.addresses = response.data.addresses;
              sessionStorage.setItem(
                "userData",
                JSON.stringify(userObj)
              );
          } else {
            opts.current.msg = response.data.message;
            showModal(true);
          }
        })
        .catch((error) => {
          dispatch(showLoader(false));
          console.error(error);
        });
    },[])
    const getStates = useCallback(()=> {
        dispatch(showLoader(true));
        axios
          .get(`data/states.json`)
          .then((response) => {
            dispatch(showLoader(false));
            if (response.data && response.data.length) {
              setStates(response.data);
            } else {
              opts.current.msg = 'An Error Occured.';
              showModal(true);
            }
          })
          .catch((error) => {
            dispatch(showLoader(false));
            console.error(error);
          });
    },[]);

    useEffect(()=>{
        if(!states.length && isLoggedIn){
          getStates();
        }
    },[states, getStates]);

    const getStateById = (stateId) => {
        let stateObj = states.find((state)=>state.stateId === stateId);
        if(!stateObj || !stateObj.stateNameEn){
          stateObj = states.find((state)=>state.stateId === CONST.defaultStateId)
        }
        return stateObj?.stateNameEn;
    }
    const manageAddress = (e) => {
        e.preventDefault();
        dispatch(showLoader(true));
        axios
          .post(`${CONST.apiBase}data.php`, addObj)
          .then((response) => {
            if (response.data.status === "success") {
              getAddresses();
              setShowAddForm(false);
              

              opts.current.title = 'Success.';
              if(addObj.id){
                opts.current.msg = 'Address Updated Successfully.';
              }else{
                opts.current.msg = 'Address Added Successfully.';
              }
              showModal(true);
            } else {
              opts.current.title = 'Error.';
              opts.current.msg = response.data.msg || 'An Error Occured.';
              showModal(true);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(()=>{
            dispatch(showLoader(false));
          });
    }
    const saveUserAccount = async() => {
      console.log('>>> userData:', userData);
      dispatch(showLoader(true));
      const response = await axios.post(`${CONST.apiBase}data.php`,userData);
      dispatch(showLoader(false));
      if(response && response.data && response.data.status === 'success'){
        opts.current.title = 'Success';
        opts.current.msg = 'Data Updated successfully.';
        showModal(true);
        setDisableAccount(true);
        let tempData = {...userObj};
        tempData.firstName = userData.firstName;
        tempData.lastName = userData.lastName;
        tempData.email = userData.email;

        const userDataObj = JSON.parse(sessionStorage.getItem('userData')) || {};
              userDataObj.firstName = userData.firstName;
              userDataObj.lastName = userData.lastName;
              userDataObj.email = userData.lastName;
              sessionStorage.setItem(
                "userData",
                JSON.stringify(userDataObj)
              );

        //sessionStorage.setItem('userData',JSON.stringify(tempData));
      }else{
        opts.current.title = 'Error';
        opts.current.msg = 'Error Occured. Please Try Again.';
        showModal(true);
      }
    }
    const userAccountFieldChanged = (e) => {
      const name = e.target.name;
      const value = e.target.value;
      let temp = {...userData};
      temp[name]= value;
      setUserData(temp);
    }
    const addressFieldChanged = (e) => {
      const name = e.target.name;
      const value = e.target.value;
      let temp = {...addObj};
      if(name==='isDefault' && !e.target.checked){
        temp[name]= '';
      }else{
        temp[name]= value;
      }
      setAddObj(temp);
    }
    const editUserAccount = () => {
      setDisableAccount(false);
    }
    const addNewAddress = () => {
      setAddObj(defAddObj);
      setShowAddForm(true);
      setTimeout(()=>{
        document.getElementById('addressForm').scrollIntoView({behavior:'smooth'});
      },100);
    }
    const editUserAddress = (addId) => {
      const addToEdit = addresses.find((add)=>add.id === addId);
      if(addToEdit){
        let temp = {
          type:'addUpdateUserAddress',
          id:addToEdit.id,
          userId:userObj.userId,
          flatNo:addToEdit.flatNo,
          village:addToEdit.village,
          taluka:addToEdit.taluka,
          dist:addToEdit.dist,
          state:addToEdit.state,
          pincode:addToEdit.pincode,
          isDefault:addToEdit.isDefault
        }
        setAddObj(temp);
      }
      setShowAddForm(true);
      setTimeout(()=>{
        document.getElementById('addressForm').scrollIntoView({behavior:'smooth'});
      },100);
    }

    if(!isLoggedIn){
      window.location.href = "/#/login";
      return;
    }
    return (
        <section className="bg-teal-100">
          <div className="flex justify-center items-center">
            <div className="container mx-auto px-2 md:px-4 lg:px-20 justify-center items-center">
              
                <div className="w-full p-4 my-4 mx-auto lg:w-9/12 rounded-2xl shadow-2xl bg-teal-200">
                  <div className="flex h-32 w-full items-center  rounded-lg bg-teal-900 p-3">
                    <div className="mx-auto p-8"><Logo/></div>
                  </div>
                  <div className="flex mt-1">
                  
                    <a href="/" 
                    className="transition ease-in duration-300 inline-flex items-center font-medium bg-purple-500 px-1 py-0.5 hover:shadow-lg tracking-wider text-white text-sm rounded-full hover:bg-purple-600">
                      <ArrowUturnLeftIcon width={16} height={16} style={{marginRight:'5px'}}/>
                      Back to home
                    </a>
                    {(userObj.firstName && userObj.lastName && userObj.email && addresses.length && shoppingCart.length)?(
                      <a href="/#/my-cart" 
                      className="ml-1 transition ease-in duration-300 inline-flex items-center font-medium bg-purple-500 px-1 py-0.5 hover:shadow-lg tracking-wider text-white text-sm rounded-full hover:bg-purple-600">
                        My Cart
                        <ArrowUturnRightIcon width={16} height={16} style={{marginLeft:'5px'}}/>
                      </a>
                    ):null}
                    
                  </div>
                    <div className='user-account-details m-2 md:m-4 p-2 md:p-4 border border-teal-800 rounded'>
                        <div className="flex">
                          <h2 className="mt-2">
                            <span className="font-bold uppercase lg:text-2xl text-base">Your Account Details</span>
                            {disableAccount?(
                              <button 
                              className="transition ease-in duration-300 inline-flex items-center font-medium bg-purple-500 px-1 py-0.5 hover:shadow-lg tracking-wider text-white text-sm rounded-full hover:bg-purple-600 ml-1"
                              onClick={editUserAccount}
                              >
                                <PencilSquareIcon width={16} height={16} style={{marginRight:'2px'}}/>
                                <span>Edit</span>
                              </button>
                            ):null}
                            
                          </h2>
                        </div>
                        <div>
                          <input name="type" type="hidden" value="manageAddress"/>
                          <input name="userId" type="hidden" value={userObj.userId}/>
                          <input name="timestamp" type="hidden" value={new Date().getTime()}/>
                        </div>
                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                          <div>
                            <label className="text-slate-500 font-bold float-left">First Name/स्वतःचे नाव<span className="text-red-500 mx-1">*</span>:</label>
                            <input 
                            className={`w-full text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline ${disableAccount?disabledClasses:enabledClasses}`}
                            name="firstName"
                            required
                            value={userData.firstName}
                            disabled={disableAccount}
                            onChange={userAccountFieldChanged}
                            type="text" placeholder="First Name*" />
                          </div>
                          <div>
                            <label className="text-slate-500 font-bold float-left">Last Name/आडनाव<span className="text-red-500 mx-1">*</span>:</label>
                            <input 
                            className={`w-full text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline ${disableAccount?disabledClasses:enabledClasses}`}
                            name="lastName"
                            required
                            value={userData.lastName}
                            disabled={disableAccount}
                            onChange={userAccountFieldChanged}
                            type="text" placeholder="Last Name*" />
                          </div>
                          <div>
                            <label className="text-slate-500 font-bold float-left">Contact Number<span className="text-red-500 mx-1">*</span>:</label>
                            <input 
                            className="w-full text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline cursor-not-allowed"
                            name="mobile"
                            required
                            disabled
                            value={userData.mobile}
                            type="tel" placeholder="Contact Number*" />
                          </div>
                          <div>
                            <label className="text-slate-500 font-bold float-left">Email address<span className="text-red-500 mx-1">*</span>:</label>
                              <input 
                              className={`w-full text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline ${disableAccount?disabledClasses:enabledClasses}`}
                              type="email" name="email" 
                              required
                              disabled={disableAccount}
                              value={userData.email}
                              onChange={userAccountFieldChanged}
                              placeholder="Email address*" />
                          </div>
                        </div>
                        {disableAccount?null:(
                          <div className="my-4 w-full">
                            <button 
                            className="transition ease-in duration-300 inline-flex items-center text-sm font-medium mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600 "
                            disabled={!(userData.firstName&&userData.lastName && userData.email)}
                            onClick={saveUserAccount}
                            >
                              <span>Update</span>
                            </button>
                            
                          </div>
                        )}
                    </div>
                    <div className='user-addresses m-2 md:m-4 p-2 md:p-4 border border-teal-800 rounded'>
                      <div className="flex">
                        <h2 className="font-bold uppercase lg:text-2xl text-base mt-2">Your Delivery Addresses</h2>
                      </div>
                      <div className="grid grid-cols-1 gap-5 mt-5">
                        {addresses.map((address,idx)=>(
                          <div key={address.id} className="flex items-center mb-2">
                            <label className="text-xs text-slate-500 font-bold float-left text-left mx-2">
                              <span>
                                {idx+1}. {address.firstName} {address.lastName}, {address.flatNo}, At: {address.village}, Tal: {address.taluka}, Dist: {address.dist}, Pincode: {address.pincode}, State: {getStateById(address.state)}</span>
                            </label>
                            <button 
                              className="transition ease-in duration-300 inline-flex items-center font-medium bg-purple-500 px-1 py-0.5 hover:shadow-lg tracking-wider text-white text-sm rounded-full hover:bg-purple-600 ml-1"
                              onClick={()=>editUserAddress(address.id)}
                              >
                                <PencilSquareIcon width={16} height={16} style={{marginRight:'2px'}}/>
                                <span>Edit</span>
                              </button>
                          </div>
                        ))}
                      </div>
                      <div className="my-4 w-full text-left mx-2">
                        OR
                      </div>
                      <div className="my-4 w-full text-left mx-2">
                        <button 
                        className="transition ease-in duration-300 inline-flex items-center text-sm font-medium mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600"
                        onClick={addNewAddress}
                        >
                          <span>Add New Address</span>
                        </button>
                      </div>
                    </div>
                    {showAddForm?(
                      <form id="addressForm" name="addressForm" onSubmit={manageAddress}>
                        <div className='user-new Address m-2 md:m-4 p-2 md:p-4 border border-teal-800 rounded'>
                          <div className="flex">
                            <h2 className="font-bold uppercase lg:text-2xl text-base mt-2">
                            {addObj.id?'Update':'Add New'} Address</h2>
                          </div>
                          <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            <div>
                              <label className="text-slate-500 font-bold float-left">Flat No./घर क्र<span className="text-red-500 mx-1">*</span>:</label>
                                <input 
                                className="w-full bg-gray-100 text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                type="text" name="flatNo" 
                                required
                                maxLength={160}
                                value={addObj.flatNo}
                                onChange={addressFieldChanged}
                                placeholder="Flat No./घर क्र. वगैरे"/>
                            </div>
                            <div>
                              <label className="text-slate-500 font-bold float-left">Village/गाव<span className="text-red-500 mx-1">*</span>:</label>
                                <input 
                                className="w-full bg-gray-100 text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                type="text" name="village" 
                                required
                                value={addObj.village}
                                onChange={addressFieldChanged}
                                placeholder="Village/गाव*" />
                            </div>
                            <div>
                              <label className="text-slate-500 font-bold float-left">Tehsil/तालुका<span className="text-red-500 mx-1">*</span>:</label>
                                <input 
                                className="w-full bg-gray-100 text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                type="text" name="taluka" 
                                required
                                value={addObj.taluka}
                                onChange={addressFieldChanged}
                                placeholder="Tehsil/तालुका*" />
                            </div>
                            <div>
                              <label className="text-slate-500 font-bold float-left">District/जिल्हा<span className="text-red-500 mx-1">*</span>:</label>
                                <input 
                                className="w-full bg-gray-100 text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                type="text" name="dist" 
                                required
                                value={addObj.dist}
                                onChange={addressFieldChanged}
                                placeholder="District/जिल्हा*" />
                            </div>
                            <div>
                              <label className="text-slate-500 font-bold float-left">State<span className="text-red-500 mx-1">*</span>:</label>
                              <select 
                                className="w-full bg-gray-100 text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                value={addObj.state}
                                required placeholder="Select State" name="state"
                                onChange={addressFieldChanged}
                                >
                                  <option value=""> Select an Option</option>
                                  {states.map((state)=>(
                                    <option key={state.stateId} value={state.stateId} >{state.stateNameEn}</option>
                                  ))}
                              </select>
                            </div>
                            <div>
                              <label className="text-slate-500 font-bold float-left">Pincode<span className="text-red-500 mx-1">*</span>:</label>
                              <input 
                              className="w-full bg-gray-100 text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                              type="tel" name="pincode" minLength={6} maxLength={6} pattern="[0-9]{6}"
                              required
                              value={addObj.pincode}
                              onChange={addressFieldChanged}
                              placeholder="Pincode*" />
                            </div>
                          </div>
                          <div className="grid grid-cols-1 gap-5 mt-5">
                            <div className="flex items-center">
                                <input id="isDefault" name="isDefault" type="checkbox" 
                                value="1"
                                checked={addObj.isDefault === '1'}
                                onChange={addressFieldChanged}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded mb-2.5 md:mb-0"/>
                                <label htmlFor="isDefault" className="text-xs text-slate-500 font-bold float-left text-left mx-2">
                                हा माझा प्राथमिक पत्ता आहे.
                                </label>
                            </div>
                            <div className="flex items-center mb-4">
                              <input id="recheckAddress" type="checkbox" value="yes" required 
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded mb-2.5 md:mb-0"/>
                              <label htmlFor="recheckAddress" className="text-xs text-slate-500 font-bold float-left text-left mx-2">
                                <span className="text-red-500 mx-1">*</span>
                                मी संपूर्ण पत्ता पुन्हा एकदा पडताळून पाहिला आहे, आता या पत्त्यावर माझी ऑर्डर द्यायला काही हरकत नाही.
                              </label>
                            </div>
                          </div>
                          <div className="my-4 w-full">
                            <button 
                            className="transition ease-in duration-300 inline-flex items-center text-sm font-medium mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600 "
                            type="submit"
                            >
                              <span>{addObj.id?'Update':'Add'} Address</span>
                            </button>
                            <BackToHome title="Cancel"/>
                          </div>
                        </div>
                      </form>
                    ):null}
                  
                </div>
              
            </div>
          </div>
        </section>
    )
}
export default ManageAddress;