import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from "axios";
import Book from '../book';
import Advertise from '../advertise';
import CONST from "../../../common/constants";
import useFetch from '../../../../helpers/useFetch';
import {gotoCategoryPage} from '../../../../helpers/helpers';
const Books = ({setSelBook}) => {
    const [categories,setCategories] = useState([]);
    const [searchData,setSearchData] = useState([]);
    const [showSearchBooks,setShowSearchBooks]= useState(false);
    const catWiseData = useFetch('data',{type:'getHomePageData'});
    const searchText = useSelector((state) => state.searchText);
   
    useEffect(()=>{
        if(searchText){
            (async () => {
                console.log('>> searchText:', searchText);
                const payload = {type:'searchBookByName',text:searchText.toLowerCase()};
                const response = await axios.post(`${CONST.apiBase}data.php`,payload);
                if(response && response.data && response.data.rows){
                    setSearchData(response.data.rows);
                    setShowSearchBooks(true);
                }
                console.log('>>> searchedBooks:', response);
              })();
        }else{
            setShowSearchBooks(false);
        }
    },[searchText]);

    useEffect(()=>{
        if(!categories.length && catWiseData && catWiseData.rows){
            let cats = [];
            catWiseData.rows.forEach((row)=>{
                const existing = cats.find((cat)=>cat.catId ==row.catId);
                if(existing){
                    existing.books.push(row);
                }else{
                    const obj = {
                        catId:row.catId,
                        priority:row.catPriority,
                        catNameEn:row.catNameEn,
                        catNameMr:row.catNameMr,
                        books:[
                            row
                        ]
                    };
                    cats.push(obj);
                }
            });
            cats.sort((catA,catB)=>catA.priority-catB.priority);
            setCategories(cats);
        }
    },[categories, catWiseData]);
    return (
        <>
        {showSearchBooks?(
            <div className="flex w-full flex-wrap sm:w-fit">
                <div className="mx-4 mt-8 mb-2 inline-flex w-full font-bold">
                    <h3 className="text-base md:text-lg w-3/4 text-left">
                    Results for "{searchText}"
                    </h3>
                </div>
                {searchData.map((book)=>(
                    <Book key={book.bookId} book={book} setSelBook = {setSelBook}/>
                ))}
            </div>
        ):(
            <div className="flex w-full flex-wrap sm:w-fit">
                <Advertise/>
                {categories.map((category)=>(
                    <>
                    <div className="mx-4 mt-8 mb-2 inline-flex w-full font-bold">
                        <h1 className="text-lg md:text-2xl uppercase w-3/4 text-left">{category.catNameEn} | {category.catNameMr}</h1>
                        <div className="text-right mt-0 md:mt-2 w-1/4 mr-2">
                            <span className="cursor-pointer" onClick={()=>gotoCategoryPage(category)}>View All ...</span>
                        </div>
                    </div>
                    {category.books.map((book)=>(
                        <Book key={book.bookId} book={book} setSelBook = {setSelBook}/>
                    ))}
                    </>
                ))}
            </div>
        )}
        </>
    );
}
export default Books;