import { useState, useEffect } from "react";
import useFetch from "../../../helpers/useFetch";
const Sidebar = () => {
    const deviceMenuClass = `flex px-4 py-2 justify-left text-white hover:text-gray-200 text-xs md:text-base focus:underline`;
    const [categories,setCategories] = useState([]);
    const [selectedCategory,setSelectedCategory] = useState('');

    const cats = useFetch('data',{type:'getAllCategories'});
    return (
        <div className="flex items-center">
            <div className="category-menu w-full"> 
                <div className={`text-base list-none bg-teal-500 divide-y divide-gray-100 shadow`}>
                    <div className="px-4 py-3">
                        <span className="flex font-bold text-white text-xs md:text-base focus:underline">Categories</span>
                    </div>
                    <ul className="py-2">
                        <li className={deviceMenuClass}>
                            Home
                        </li>
                        <li><a className={deviceMenuClass} href="/">
                            All Books</a></li>
                        <li><a className={deviceMenuClass} href="/#/contact-us">
                            Contact Us</a></li>
                    </ul>
                </div>
            </div>
            
        </div>
    )
}
export default Sidebar;