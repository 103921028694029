import {useState,useEffect,useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from "axios";
import {showLoader, removeFromCart, increaseQty,decreaseQty} from '../../../middleware/actions';
import CONST from "../../common/constants";
import {PlusCircleIcon, MinusCircleIcon, TrashIcon} from '@heroicons/react/24/outline';
import BackToHome from '../back-to-home';
import Header from '../header';
import Footer from '../footer';
import FlashMessage from '../main-view/flash-message';

const MyCart = () => {
    const formAction = `${CONST.apiBase}cart.php`;
    const dispatch = useDispatch();
    const shoppingCart = useSelector((state) => state.cart);
    const userObj = JSON.parse(sessionStorage.getItem('userData')) || {};
    const [cartObj,setCartObj]  = useState({items:[],total:0});
    const [addressId,setAddressId] = useState('');
    const [states,setStates] = useState([]);
    const [addresses, setAddresses] = useState(userObj.addresses||[]);
    const [orderIds,setOrderIds] = useState({rzp:'',mhb:'',amt:0});
    const post_to_url = (path, params, method) =>{
        method = method || "post";
        var form = document.createElement("form");
        form.setAttribute("method", method);
        form.setAttribute("action", path);
    
        for(var key in params) {
            if(params.hasOwnProperty(key)) {
                var hiddenField = document.createElement("input");
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("name", key);
                hiddenField.setAttribute("value", params[key]);
    
                form.appendChild(hiddenField);
             }
        }
        document.body.appendChild(form);
        form.submit();
    }
    useEffect(()=>{
        if(shoppingCart && shoppingCart.length){
            let items = [];
            let total = 0;
            shoppingCart.forEach((item)=>{
                let temp = {...item};
                if(!temp.qty){
                    temp.qty = 1;
                }
                temp.subTotal = (temp.qty*parseInt(item.spINR));
                total+=temp.subTotal;
                items.push(temp);
            })
            setCartObj({items,total})
        }
    },[shoppingCart, states]);
    const setDefAddress = () => {
        const defAddress = addresses.find((add)=>add.isDefault === '1');
        if(defAddress){
            setAddressId(defAddress.id);            
        }else{
            let firstAddress = addresses[0];
            if(firstAddress){
                setAddressId(firstAddress.id);
            }
        }
    }
    const removeCartItem = (bookId) => {
        dispatch(removeFromCart(bookId));
    }
    const decrease = (bookId) => {
        dispatch(decreaseQty(bookId));
    }
    const increase = (bookId) => {
        dispatch(increaseQty(bookId));
    }
    const addressChanged = (e) => {
        setAddressId(e.target.value);
    }
    const getStateById = (stateId) => {
        let stateObj = states.find((state)=>state.stateId === stateId);
        if(!stateObj || !stateObj.stateNameEn){
          stateObj = states.find((state)=>state.stateId === CONST.defaultStateId)
        }
        return stateObj?.stateNameEn;
    }
    const getStates = useCallback(()=> {
        dispatch(showLoader(true));
        axios
          .get(`data/states.json`)
          .then((response) => {
            dispatch(showLoader(false));
            if (response.data && response.data.length) {
              setStates(response.data);
            }else {
              //opts.current.msg = 'An Error Occured.';
              //showModal(true);
            }
          })
          .catch((error) => {
            dispatch(showLoader(false));
            console.error(error);
          });
    },[]);
    const getUserCart = ()=>{
        let cartPayload = [];
        cartObj.items.forEach((cartItem)=>{
            if(cartItem.qty){
                cartPayload.push({i:cartItem.bookId,q:cartItem.qty,a:cartItem.spINR});
            }
        });
        return JSON.stringify(cartPayload);
    }
    const checkoutCart = () => {
        const userName = userObj.firstName+' '+userObj.lastName;
        const userEmail = userObj.email;
        let payload = {
            type:'generateOrderId',
            userName,
            userEmail,
            userId:userObj.userId,
            mobile:userObj.phone,
            addressId:addressId,
            cart:getUserCart(),
            amount: cartObj.total,
            timestamp: new Date().getTime()
        };
        post_to_url(
            formAction,
            payload
        );
    }
    useEffect(()=>{
        if(addresses && addresses.length){
            setDefAddress();
            getStates();
        }
    },[addresses])
    return (
        <>
        <Header/>
        <div className="m-4">
            <div>
                <span className="font-base text-yellow-500 text-left">ऑर्डर देण्याआधी आपलं नाव, नंबर, पत्ता व्यवस्थित तपासून घ्या. My Account वर जाऊन आपण आपली सगळी माहिती Edit / Delete करू शकता.</span>
            </div>
            <header className="py-4 border-b border-gray-100">
                <h2 className="font-semibold text-gray-800 text-left">Shopping Cart</h2>
            </header>
            <div className="grid grid-flow-row-dense md:grid-cols-3 grid-cols-1">
                <div className="col-span-2 border m-1 p-1 md:m-2 md:p-2">
                    <div className="overflow-x-auto">
                        <div className="px-5 py-1 border-b border-gray-100">
                            <h3 className="font-semibold text-gray-800">Cart Details</h3>
                        </div>
                        <table className="table-auto w-full">
                            <thead className="text-xs font-semibold uppercase text-gray-50 bg-teal-500">
                                <tr>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">#</div>
                                    </th>
                                    <th className="p-2 whitespace-normal">
                                        <div className="font-semibold text-left">Product</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Price</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Quantity</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Subtotal</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center"></div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="text-sm divide-y divide-gray-100">
                                {cartObj.items.map((book, index)=>(
                                    <tr key={index}>
                                        <td className="p-2 whitespace-nowrap">
                                            <div className="text-center">{index+1}</div>
                                        </td>
                                        <td className="p-2 whitespace-normal">
                                            {/* <div className="inline-flex">
                                            <img 
                                                src={`book-thumbnails/${book.img}`} 
                                                alt={book.bookNameEng} className="w-28 h-36 object-fill  rounded-md"
                                                />
                                            </div> */}
                                            <div className="text-left">{book.bookNameEng} | {book.bookNameMar}</div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                            <div className="text-center">₹{book.spINR}</div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                            <div className="text-center inline-flex">
                                                <MinusCircleIcon className="cursor-pointer" width={20} onClick={()=>decrease(book.bookId)}/>
                                                <span className="mx-2">{book.qty}</span>
                                                <PlusCircleIcon className="cursor-pointer" width={20} onClick={()=>increase(book.bookId)}/>
                                            </div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                            <div className="text-center">₹{book.subTotal}</div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                            <div className="text-center">
                                                <TrashIcon className="cursor-pointer" width={20} onClick={()=>removeCartItem(book.bookId)}/>
                                            </div>
                                        </td>
                                    </tr>
                                ))}  
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="border m-2 p-2">
                    <div className="px-5 py-1 border-b border-gray-100">
                        <h3 className="font-semibold text-gray-800">Cart Totals</h3>
                    </div>
                    <hr/>
                    <div className="m-4 p-4">
                        <table className="table-auto w-full">
                            <tbody className="text-sm divide-y divide-gray-100">
                                <tr>
                                    <td className="p-2 whitespace-normal">
                                        <div className="text-right md:text-left">Sub Total</div>
                                    </td>
                                    <td className="p-2 whitespace-nowrap">
                                        <div className="text-left">₹{cartObj.total}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-2 whitespace-normal">
                                        <div className="text-right md:text-left">Delivery Charges</div>
                                    </td>
                                    <td className="p-2 whitespace-nowrap">
                                        <div className="text-left">₹0</div>
                                    </td>
                                </tr>
                                <tr className="font-bold">
                                    <td className="p-2 whitespace-normal">
                                        <div className="text-right md:text-left">Total</div>
                                    </td>
                                    <td className="p-2 whitespace-nowrap">
                                        <div className="text-left">₹{cartObj.total}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <header className="pt-8 border-b border-gray-100">
                <h2 className="font-semibold text-gray-800 text-left">Select Delivery Address</h2>
            </header>
            <div className="grid grid-flow-row-dense grid-cols-1">
                <div className="grid grid-cols-1 m-2 p-2">
                    {addresses.map((address)=>(
                        <div key={address.id} className="flex items-center mb-2">
                        <input id={`address-${address.id}`} name="billingAddress" type="radio" value={address.id} required 
                        onChange={addressChanged} checked={addressId === address.id}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded mb-2.5 md:mb-0"/>
                        <label htmlFor={`address-${address.id}`} className="text-xs text-slate-500 font-bold float-left text-left mx-2">
                            <span>
                            {address.firstName} {address.lastName}, {address.flatNo}, At: {address.village}, Tal: {address.taluka}, Dist: {address.dist}, Pincode: {address.pincode}, State: {getStateById(address.state)}, Mobile: {address.mobile}, Email: {address.email}</span>
                        </label>
                        </div>
                    ))}
                </div>
            </div>
            <FlashMessage/>
            <div className="my-4 w-full">
                <button 
                className="transition ease-in duration-300 inline-flex items-center text-sm font-medium mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600 disabled:hover:bg-teal-500 disabled:cursor-not-allowed"
                type="button"
                disabled={!(cartObj.total &&addressId && userObj.firstName && userObj.lastName &&userObj.email)}
                onClick={checkoutCart}
                >
                    <span>Pay ₹{cartObj.total} & Checkout</span>
                </button>
                <BackToHome title="Cancel"/>
            </div>
        </div>
        <Footer/>
        </>
    );
}
export default MyCart;
