// reducer.js
import { 
  SHOW_lOADER, 
  SHOW_TOASTER, 
  ADD_TO_CART, 
  REMOVE_FROM_CART,
  INCREASE_QTY,
  DECREASE_QTY, 
  SEARCH_TEXT,
} from './actions';

const initialState = {
  loader: false,
  toaster:{show:false,type:'warn',msg:''},
  cart:JSON.parse(sessionStorage.getItem('userCart')) || [],
  searchText:''
};

const mbReducer = (state = initialState, action) => {
  let tempCart = [];
  switch (action.type) {
    case SHOW_lOADER:
      return {
        ...state,
        loader: action.payload,
      };
    break;
    case SHOW_TOASTER:
      return {
        ...state,
        toaster: action.payload,
      };
    break;
    case ADD_TO_CART:
      tempCart = [...state.cart];
      let existing = tempCart.find((b)=>b.bookId === action.payload.bookId);
      if(existing){
        existing.qty++;
      }else{
        tempCart = [...state.cart,action.payload];
      }
      sessionStorage.setItem('userCart',JSON.stringify(tempCart));
        return {
          ...state,
          cart: tempCart,
        };
    break;
    case REMOVE_FROM_CART:
      tempCart = [...state.cart];
      tempCart = tempCart.filter((book)=>book.bookId!==action.payload);
      sessionStorage.setItem('userCart',JSON.stringify(tempCart));
      return {
        ...state,
        cart: [...tempCart],
      };
    break;
    case DECREASE_QTY:
      tempCart = [...state.cart];
      let item = tempCart.find((book)=>book.bookId === action.payload);
      if(item.qty){
        item.qty--;
      }
      sessionStorage.setItem('userCart',JSON.stringify(tempCart));
      return {
        ...state,
        cart: [...tempCart],
      };
    break;
    case INCREASE_QTY:
      tempCart = [...state.cart];
      let itemToIncrease = tempCart.find((book)=>book.bookId === action.payload);
      itemToIncrease.qty++;
      sessionStorage.setItem('userCart',JSON.stringify(tempCart));
      return {
        ...state,
        cart: [...tempCart],
      };
    break;
    case SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
      };
      break;
    default:
      return state;
  }
};

export default mbReducer;
