import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import BackToHome from '../back-to-home';
import {
  KeyIcon,
  PhoneIcon
} from '@heroicons/react/24/outline';
import CONST from '../../common/constants';
import Logo from "../../common/logo";
import axios from "axios";
import {showLoader} from './../../../middleware/actions';
function Login({book, showModal, opts}) {
  const dispatch = useDispatch();
  const isLoggedIn = sessionStorage.getItem('isLoggedIn') === 'true';
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  if(isLoggedIn){
    window.location.href = "/";
    return;
  }

  const onNumberChange = (event)=> {
    setMobile(event.target.value);
  }
  const onPasswordChange = (event)=> {
    setPassword(event.target.value);
  }
  const handleSubmit = (event) => {
    dispatch(showLoader(true));
    event.preventDefault();
    axios
      .post(`${CONST.apiBase}login.php`, {
        type:'verifyPassword',
        mobile: mobile,
        password: password,
      })
      .then((response) => {
        dispatch(showLoader(false));
        if (response.data.status === "success") {
          sessionStorage.setItem("isLoggedIn", true);
          sessionStorage.setItem(
            "userData",
            JSON.stringify(response.data)
          );
          const hasAddress = response?.data?.hasAddress;
          if(hasAddress!=='1'){
            window.location.href = "/#/user-account";
          }else{
            if(book && book.bookId){
              window.location.href = "/#/checkout";
            }else{
              window.location.href = "/";
            }
          }
        } else {
          opts.current.msg = response.data.msg;
          showModal(true);
        }
      })
      .catch((error) => {
        dispatch(showLoader(false));
        console.error(error);
      });
  };
    return (
      <>
      <main className="flex items-center justify-center md:h-screen">
      <div className="relative mx-auto flex w-full max-w-[400px] flex-col space-y-2.5 p-4 md:-mt-32">
        <div className="flex h-32 w-full items-center  rounded-lg bg-teal-900 p-3">
          <div className="mx-auto"><Logo/></div>
        </div>
        <form className="space-y-3" name="loginForm" onSubmit={handleSubmit} >
          <div className="flex-1 rounded-lg bg-gray-50 px-6 pb-4 pt-8">
            <h1 className={`mb-3 text-2xl`}>
              Please login to continue.
            </h1>
            <div className="w-full">
              <div>
                <label
                  className="text-left mb-3 mt-5 block text-xs font-medium text-gray-900"
                  htmlFor="userPhone"
                >
                  Mobile Number<span className="text-red-500 ml-1 font-bold">*</span>
                </label>
                <div className="relative">
                  <input
                    className="peer block w-full rounded-md border border-gray-200 py-[9px] pl-10 text-sm outline-2 placeholder:text-gray-500 disabled:cursor-not-allowed"
                    id="userPhone"
                    type="tel"
                    name="userPhone"
                    placeholder="Enter 10 Digit Mobile Number"
                    onChange={onNumberChange}
                    pattern="[0-9]{10}"
                    minLength={10}
                    maxLength={10}
                    required
                    disabled={otpSent}
                  />
                  <PhoneIcon className="pointer-events-none absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />
                </div>
              </div>
              <div className="mt-4">
                <label
                  className="text-left mb-3 block text-xs font-medium text-gray-900"
                  htmlFor="userPassword"
                >
                  Password<span className="text-red-500 ml-1 font-bold">*</span>
                </label>
                <div className="relative">
                  <input
                    className="peer block w-full rounded-md border border-gray-200 py-[9px] pl-10 text-sm outline-2 placeholder:text-gray-500"
                    id="userPassword"
                    type="password"
                    name="userPassword"
                    onChange={onPasswordChange}
                    placeholder="Enter Your Password"
                    required
                    minLength={8}
                  />
                  <KeyIcon className="pointer-events-none absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <BackToHome title="Cancel"/>
              <button 
              className="transition ease-in duration-300 inline-flex items-center text-sm font-medium mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600 "
              type="submit"
              >
                <span>Login</span>
              </button>
            </div>
          </div>
        </form>
        <div className="flex-1 rounded-lg bg-gray-50 pr-0">
          <label
            className="mb-3 block text-xs font-medium text-gray-900"
          >
            New User/Don't have Password?
            <a href="/#/set-password" 
            className="ml-1 text-blue-600 hover:underline">Click Here </a>
          </label>
        </div>
      </div>
    </main>
    </>
    );
  }
  export default Login