import { useState } from "react";
import axios from "axios";
import { useDispatch } from 'react-redux';
import CONST from './../../common/constants';
import {showLoader} from '../../../middleware/actions';

const Dropdown = ({statuses, row}) => {
    const dispatch = useDispatch();
    const [value,setValue] = useState(row.orderStatus);
    const [valueChanged,setValueChanged] = useState(false);
    const dropdownChanged = (e) => {
        setValue(e.target.value);
        setValueChanged(true);
    }
    const saveStatus = (row)=>{
        dispatch(showLoader(true));
        axios.post(`${CONST.apiBase}data.php`,{
        type:'updateOrderStatus',
        orderId:row.orderId,
        statusId:value
        }).then((resp)=>{
            dispatch(showLoader(false));
            setValueChanged(false);
        }).catch((err) => {
            console.log('err:',err);
            dispatch(showLoader(false));
        });
    }
    return (
        <>
            <select 
                value={value} 
                onChange={dropdownChanged} 
                disabled={row.orderPmtStatus === '2'}
                style={{width:'70%',marginRight:'10px', padding:'5px'}}>
                {statuses.map((obj)=>(
                    <option key={`option${obj.id}`} value={obj.id} id={obj.id}>{obj.name}</option>
                ))}
            </select>
            {(valueChanged && value !=='1')?(
                <button onClick={()=> saveStatus(row)} title="Save Order Status">
                    <svg 
                    xmlns="http://www.w3.org/2000/svg" fill="none" 
                    viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" 
                    className="h-6 w-6">
                        <path strokeLinecap="round" strokeLinejoin="round" 
                        d="M3 3v18h18V6l-3-3H3ZM7.5 3v6h9V3M6 21v-9h12v9M14.25 5.25v1.5"/>
                    </svg>
                </button>
            ):null}
        </>
    )
}
export default Dropdown;