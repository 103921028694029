const Footer = () => {
    const year = new Date().getFullYear();
    return ( 
        <footer className="font-bold z-20 w-full p-2 bg-teal-200 border-teal-300 shadow block text-center justify-center">
        <div>
            <span className="text-sm text-gray-500">
                © {year} <a href="/" className="hover:underline">Mahabooks.in</a>. All Rights Reserved.
            </span>
        </div>
        <div className="text-sm mt-2 text-gray-500">
            <ul>
                <li className="inline-block me-2">
                    <a href="/#/terms-conditions" className="hover:underline me-2">Terms and Conditions</a>
                </li>
                <li className="inline-block me-2">
                    <a href="/#/cancellation-and-refund" className="hover:underline me-2">Cancellation and Refund Policy</a>
                </li>
                <li className="inline-block me-2">
                    <a href="/#/privacy-policy" className="hover:underline me-2">Privacy Policy</a>
                </li>
                <li className="inline-block me-2">
                    <a href="/#/shipping-policy" className="hover:underline me-2">Shipping Policy</a>
                </li>
                <li className="inline-block me-2">
                    <a href="/#/contact-us" className="hover:underline me-2">Contact Us</a>
                </li>
            </ul>
        </div>
        </footer>
  );
}
export default Footer;