import { useState,useEffect, useRef } from "react";
import {useParams} from "react-router-dom";
import Header from '../../header';
import Footer from '../../footer';
import OrderSummary from '../../order-summary';
import axios from "axios";
import CONST from '../../../common/constants';
import Reviews from '../../reviews';
import Book from '../book';
const BookDetails = ({setSelBook, showLoader,showModal,opts}) => {
    let urlCalled = useRef(false);
    const {bookNameId} = useParams();
    let bookNameIdArr = bookNameId.split('-');
    const bookId = bookNameIdArr[bookNameIdArr.length-1];
    const [_b,_setB] = useState({});
    const [relatedBooks,setRelatedBooks] = useState([]);
    const loadBook = async () => {
        const response = await axios.post(`${CONST.apiBase}data.php`,{
            type:'getBookDetails',
            bookId:bookId
        });
        if(response && response.data && response.data.book){
            _setB(response.data.book);
            if(response.data.relatedBooks){
                setRelatedBooks(response.data.relatedBooks);
            }
        }else{
            window.location.href = "/";
        }
    }
    useEffect(()=>{
        if(bookId && (!_b || !_b.bookId)  && !urlCalled.current){
            urlCalled.current = true;
            loadBook();
        }
    },[bookId]);
    return (
        <>
            <main className="flex min-h-screen flex-col">
                <div>
                    <Header/>
                </div>
                <div className='w-full overflow-hidden min-h-96'>
                    <div className="max-w-full overflow-hidden">
                        <div className="container w-full p-2 mx-auto rounded-md sm:p-4 text-black">
                            <OrderSummary book={_b} setSelBook={setSelBook} type='book-detail'/>
                        </div>
                    </div>
                </div>
                <div>
                    <Reviews bookId={bookId}/> 
                </div>  
                {relatedBooks.length?(
                    <div className="flex w-full flex-wrap sm:w-fit">
                        <div className="mx-4 mt-8 mb-2 inline-flex w-full font-bold">
                            <h3 className="text-base md:text-lg w-3/4 text-left">
                            You May Also Like
                            </h3>
                        </div>
                        {relatedBooks.map((book)=>(
                            <Book key={book.bookId} book={book} setSelBook = {setSelBook}/>
                        ))}
                    </div>
                ):null}   
                <div>
                    <Footer/>
                </div>
            </main>
        </>
    ); 
}
export default BookDetails;