import { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from 'react-redux';
import {showLoader} from '../../../middleware/actions';
import CONST from './../../common/constants';

const AddEditCategory = ({setShowModal, category, showErrModal,opts}) => {
    const dispatch = useDispatch();
    const title = 'Add/Edit Category';
    const [isActive,setIsActive] = useState(category.catActive);
    console.log('>>> isActive:', isActive);
    
    const activeChanged = (e) => {
        const value = e.target.checked?'1':'0';
        setIsActive(value);
    }
    const closeModal = () => {
        setShowModal(false);
    }
    const addEditCategory = async(e) => {
        e.preventDefault();
        const dataToSend = {
            catActive:isActive
        };
        Array.from(e.target.elements).forEach(element => {
            if(element.name){
                dataToSend[element.name] = element.value;
            }
        });
        dispatch(showLoader(true));
        const response = await axios.post(`${CONST.apiBase}dashboard.php`,dataToSend);
        console.log('>>> response:', response);
        dispatch(showLoader(false));
        if(response?.data?.status === 'success'){
            opts.current.title = 'Success';
            opts.current.msg = 'Category Add/Update Successfull. Refreshing Page...';
            showErrModal(true);
            setTimeout(()=>{
                window.location.reload();
            },1000);
        }else{
            opts.current.msg = response?.data?.msg || 'Something went wrong.';
            showErrModal(true);
        }
    }
    return (
        <div className="w-full h-full">
            <div id="modal-bg" onClick={closeModal} 
            className="flex items-center justify-center space-x-2 fixed inset-0 z-40 bg-gray-900 bg-opacity-60"></div>
            <div id="modal-box" 
            className="bg-teal-100 w-3/4 min-w-80 min-h-48 flex flex-col items-center gap-2 -translate-y-1/2 px-6 py-2 rounded-lg top-1/2 left-1/2 -translate-x-1/2 absolute z-40">
                <div className="modal-header w-full">
                    <span className="text-2xl font-medium">{title}</span>
                    <div className="float-right">
                    <button 
                        className="transition ease-in duration-300 inline-flex items-center text-sm font-medium mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600"
                        type="button"
                        onClick={closeModal}
                        >
                        <span>X</span>
                    </button>
                    </div>
                </div>
                <div className="modal-body">
                    <form name="checkoutForm" method="POST" onSubmit={addEditCategory}>
                        <div className="w-full p-4 my-4 mx-auto rounded-2xl shadow-2xl bg-teal-200">
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                <div style={{display:'none'}}>
                                    <input type="hidden" name="type" value="addEditCategory"/>
                                    <input type="hidden" name="categoryId" value={category.catId}/>
                                </div>
                                <div>
                                    <label className="text-slate-500 font-bold float-left">Category Name English<span className="text-red-500 mx-1">*</span>:</label>
                                    <input 
                                    className="w-full bg-gray-100 text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                    name="catNameEn"
                                    required
                                    defaultValue={category.catNameEn}
                                    type="text" placeholder="Category Name English*" />
                                </div>
                                <div>
                                    <label className="text-slate-500 font-bold float-left">Category Name Marathi<span className="text-red-500 mx-1">*</span>:</label>
                                    <input 
                                    className="w-full bg-gray-100 text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                    name="catNameMr"
                                    required
                                    defaultValue={category.catNameMr}
                                    type="text" placeholder="Category Name Marathi*" />
                                </div>
                                <div>
                                    <label className="text-slate-500 font-bold float-left">Priority<span className="text-red-500 mx-1">*</span>:</label>
                                    <input 
                                    className="w-full bg-gray-100 text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                    name="catPriority"
                                    required
                                    defaultValue={category.catPriority}
                                    type="text" placeholder="Priority*" />
                                </div>
                                <div>
                                    <div className="m-8 text-start">
                                        <label htmlFor="isCatActive" className="text-slate-500 font-bold float-left">
                                            Active
                                        </label>
                                        <input id="isCatActive" type="checkbox" checked={isActive==='1'} onChange={activeChanged}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded mb-2.5 ml-1.5 mt-1.5 md:mb-0"/>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2">
                                <button 
                                    className="transition ease-in duration-300 inline-flex items-center text-sm font-medium mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600 "
                                    type="submit"
                                    >
                                    <span>Save</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                </div>
            </div>
        </div>
    )
}
export default AddEditCategory;