
import { useState } from "react";
import axios from "axios";
import CONST from './../../common/constants';
const AddEditBook = ({book,cats,setShowModal, showLoader, showErrModal, opts}) => {
    const title=book.bookId?'Edit Book':'Add New Book';
    const [thumb, setThumb] = useState({});
    const closeModal = ()=>{
        setShowModal(false);
    }
    const addEditBook = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', thumb);
        Array.from(e.target.elements).forEach(element => {
            if(element.name){
                formData.append(element.name,element.value);
            }
        });
        formData.append('cats',selectedValues.join(','));
        try {
            showLoader(true);
            axios.post(`${CONST.apiBase}upload.php`,formData,{
                headers: {
                'Content-Type': 'multipart/form-data;charset=utf-8'
                }
            }).then((resp)=>{
                opts.current.title = 'Success';
                opts.current.msg = 'Book Added/Updated Successfully.';
                showErrModal(true);
            }).catch((err) => {
                opts.current.title = 'Error';
                opts.current.msg = 'An Error Occured.';
                showErrModal(true);
            }).finally(()=>{
                showLoader(false);
            });
        } catch (error) {
            opts.current.title = 'Error';
            opts.current.msg = 'An Error Occured.';
            showErrModal(true);
            console.error(error);
        }
    }
    const fileSelected = (e) => {
        let file = e.target.files[0];
        if(file?.size <= (40*1024) && file?.type.startsWith('image/')){
            setThumb(file);
        }else{
            opts.current.title = 'Error';
            opts.current.msg = 'Image type should be from (.jpg,.jpeg,.png,.gif) and Image size should be less than or equal to 40KB.';
            showErrModal(true);
        }
    }
    const [selectedValues,setSelectedValues] = useState((book.cats || '').split(','));
    const dropdownChanged = (e) => {
        const selectedArr = [];
        const options = e.target.selectedOptions;
        for (let op of options) {
            selectedArr.push(op.id)
        }
        setSelectedValues(selectedArr);
        //options.forEach((op)=>selectedArr.push(op.id));
        //console.log('>> selectedArr:', selectedArr);

    }
    return (
        <div className="w-full h-full">
            <div id="modal-bg" onClick={closeModal} 
            className="flex items-center justify-center space-x-2 fixed inset-0 z-40 bg-gray-900 bg-opacity-60"></div>
            <div id="modal-box" 
            className="bg-teal-100 w-3/4 min-w-80 min-h-48 flex flex-col items-center gap-2 -translate-y-1/2 px-6 py-2 rounded-lg top-1/2 left-1/2 -translate-x-1/2 absolute z-40">
                <div className="modal-header w-full">
                    <span className="text-2xl font-medium">{title}</span>
                    <div className="float-right">
                    <button 
                        className="transition ease-in duration-300 inline-flex items-center text-sm font-medium mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600"
                        type="button"
                        onClick={closeModal}
                        >
                        <span>X</span>
                    </button>
                    </div>
                </div>
                <div className="modal-body">
                    <form name="checkoutForm" method="POST" onSubmit={addEditBook}>
                        <div className="w-full p-4 my-4 mx-auto rounded-2xl shadow-2xl bg-teal-200">
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                <div style={{display:'none'}}>
                                    <input type="hidden" name="type" value="addEditBook"/>
                                    <input type="hidden" name="bookId" value={book.bookId}/>
                                    <input type="hidden" name="oldThumb" value={book.bookImg}/>
                                </div>
                                <div>
                                    <label className="text-slate-500 font-bold float-left">Book Name English<span className="text-red-500 mx-1">*</span>:</label>
                                    <input 
                                    className="w-full bg-gray-100 text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                    name="bookNameEng"
                                    required
                                    defaultValue={book.bookNameEng}
                                    type="text" placeholder="Book Name English*" />
                                </div>
                                <div>
                                    <label className="text-slate-500 font-bold float-left">Book Description English<span className="text-red-500 mx-1">*</span>:</label>
                                    <input 
                                    className="w-full bg-gray-100 text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                    name="bookDescEng"
                                    required
                                    defaultValue={book.bookDescEng}
                                    type="text" placeholder="Book Description English*" />
                                </div>
                                <div>
                                    <label className="text-slate-500 font-bold float-left">Book Name Marathi<span className="text-red-500 mx-1">*</span>:</label>
                                    <input 
                                    className="w-full bg-gray-100 text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                    name="bookNameMar"
                                    required
                                    defaultValue={book.bookNameMar}
                                    type="text" placeholder="Book Name Marathi*" />
                                </div>
                                <div>
                                    <label className="text-slate-500 font-bold float-left">Book Description Marathi<span className="text-red-500 mx-1">*</span>:</label>
                                    <input 
                                    className="w-full bg-gray-100 text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                    type="text" name="bookDescMar" 
                                    required
                                    defaultValue={book.bookDescMar}
                                    placeholder="Book Description Marathi*" />
                                </div>
                                <div>
                                    <label className="text-slate-500 font-bold float-left">Book MRP<span className="text-red-500 mx-1">*</span>:</label>
                                    <input 
                                    className="w-full bg-gray-100 text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                    type="number" name="bookMRP" 
                                    pattern="[0-9]{4}"
                                    maxLength={4}
                                    required
                                    defaultValue={book.bookMRP}
                                    placeholder="Book MRP"/>
                                </div>
                                <div>
                                    <label className="text-slate-500 font-bold float-left">Book SP<span className="text-red-500 mx-1">*</span>:</label>
                                    <input 
                                    className="w-full bg-gray-100 text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                    type="number" name="bookSP"
                                    maxLength={4} 
                                    pattern="[0-9]{4}"
                                    required
                                    defaultValue={book.bookSP}
                                    placeholder="Book SP*" />
                                </div>
                                {/* <div>
                                    <label className="text-slate-500 font-bold float-left">Quantity<span className="text-red-500 mx-1">*</span>:</label>
                                    <input 
                                    className="w-full bg-gray-100 text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                    type="number" name="bookQty" 
                                    pattern="[0-9]{4}"
                                    maxLength={4}
                                    required
                                    defaultValue={book.bookQty}
                                    placeholder="Quantity*" />
                                </div> */}
                                <div>
                                    <label className="text-slate-500 font-bold float-left">Categories<span className="text-red-500 mx-1">*</span>:</label>
                                    <select 
                                        // value={value} 
                                        multiple
                                        onChange={dropdownChanged}
                                        style={{width:'70%',marginRight:'10px', padding:'5px'}}>
                                        {cats.current.map((obj)=>(
                                            <option key={`option${obj.catId}`} value={obj.catId} id={obj.catId} selected={selectedValues.indexOf(obj.catId)>-1}>{obj.catNameEn}</option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label className="text-slate-500 font-bold float-left">Thumbnail
                                    {book.bookId===''?(<span className="text-red-500 mx-1">*</span>):null}:(.jpg,.jpeg,.png,.gif with size less than 40KB)</label>
                                    <input 
                                    className="w-full bg-gray-100 text-gray-900 mt-1 p-2 rounded-lg focus:outline-none focus:shadow-outline"
                                    type="file"
                                    accept=".jpg,.jpeg,.png,.gif"
                                    id='fileToUpload'
                                    required = {!book.bookId}
                                    defaultValue=""
                                    onChange={fileSelected}
                                    placeholder="Thumbnail" />
                                </div>
                            </div>
                            <div className="mt-2">
                                <button 
                                    className="transition ease-in duration-300 inline-flex items-center text-sm font-medium mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600 "
                                    type="submit"
                                    >
                                    <span>Save</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                </div>
            </div>
        </div>
    )
}
export default AddEditBook;