import {BellAlertIcon, UserCircleIcon, Bars4Icon} from '@heroicons/react/24/outline';
import Logo from "../../common/logo";
const DbHeader = () => {
    const userObj = JSON.parse(sessionStorage.getItem('adminData'));
    const logoutUser = () => {
        sessionStorage.removeItem('adminData');
        window.location.reload();
    };
    return (
        <section className="relative mx-auto">
            <nav className="flex justify-between bg-teal-900 text-white">
                <div className="px-2 py-2 flex w-full items-center">
                    <Logo/>
                    <ul className="hidden md:flex px-4 mx-auto font-semibold font-heading space-x-12">
                        <li><a className="hover:text-gray-200 focus:underline" href="/#/dashboard/orders">Orders</a></li>
                        <li><a className="hover:text-gray-200 focus:underline" href="/#/dashboard/books">Books</a></li>
                        <li><a className="hover:text-gray-200 focus:underline" href="/#/dashboard/categories">Categories</a></li>
                        <li><a className="hover:text-gray-200 focus:underline" href="/#/dashboard/other-settings">Other Settings</a></li>
                        {/* <li><a className="hover:text-gray-200 focus:underline" href="/#/dashboard/users">Users</a></li> */}
                    </ul>
                    {/* <div className="hidden xl:flex items-center space-x-5 items-center">
                        <button className="mx-2"><BellAlertIcon width={24}/></button>
                        <button className="mx-2"><UserCircleIcon width={24}/></button>
                    </div> */}
                </div>
                {/* <div className="xl:hidden flex mr-6 items-center">
                    <button className="mx-2"><BellAlertIcon width={24}/></button>
                    <button className="mx-2"><Bars4Icon width={24}/></button>
                </div> */}
                <div className="flex items-center p-1 md:p-2">
                    <div className="float-right text-gray-200 font-semibold">
                    <UserCircleIcon className="w-6 inline-block"/>
                    <span className="block text-xs md:text-sm">{userObj.mobile}</span>
                    <button 
                    className="inline-block text-xs px-1 py-1 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-0"
                    onClick={logoutUser}
                    >
                        Logout</button>
                    </div>
                </div>
            </nav>
        </section>
    )
}
export default DbHeader;