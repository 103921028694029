import { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSearchText } from '../../../middleware/actions';
import {ShoppingCartIcon, UserCircleIcon, Bars4Icon, HomeIcon, 
    BookOpenIcon,MagnifyingGlassIcon, CircleStackIcon,
    MapPinIcon, ArrowLeftStartOnRectangleIcon,
    KeyIcon,PhoneIcon, XMarkIcon

} from '@heroicons/react/24/outline';
import Logo from "../../common/logo";
import {gotoMyCartPage} from '../../../helpers/helpers';

const Header = () => {
    const dispatch = useDispatch();
  const shoppingCart = useSelector((state) => state.cart);
  const isLoggedIn = sessionStorage.getItem('isLoggedIn') === 'true';
  const userObj = JSON.parse(sessionStorage.getItem('userData'));
  const [hiddenClass,setHiddenClass] = useState('hidden');
  const [cartItemCount,setCartItemCount] = useState(0);
  const [text,setText] = useState('');
  const deviceMenuClass = `flex px-4 py-2 justify-left text-white hover:text-gray-200 text-xs md:text-base focus:underline`;
  const logoutUser = (e) => {
    e.preventDefault();
    sessionStorage.removeItem('isLoggedIn');
    sessionStorage.removeItem('userData');
    window.location.reload();
    return false;
  }
  const toggleMenu = () => {
    setHiddenClass((hiddenClass === 'hidden'?'':'hidden'));
  }
  const goToCart = () => {
    gotoMyCartPage();
  }
  const searchBook = async() => {
    dispatch(setSearchText(text));    
  }
  const inputChanged = (e)=> {
    setText(e.target.value);
  }
  const clearText = (e)=> {
    setText('');
    dispatch(setSearchText('')); 
    document.getElementById('searchbar').value='';
    document.getElementById('deviceSearchbar').value='';
  }
  useEffect(()=>{
    let count = 0;
    shoppingCart.forEach((book)=>{count = count+(book.qty||1)});
    setCartItemCount(count);
  },[shoppingCart])

    return (
        <header>
            <section className="relative mx-auto">
                <nav className="flex justify-between bg-teal-900 text-white">
                    <div className="grid grid-cols-2 lg:grid-cols-5 md:grid-cols-6 w-full p-1 md:p-2">
                        <div className="flex col-span-1 lg:col-span-1 md:col-span-2">
                            <Logo/>
                        </div>
                        <div className="lg:col-span-3 md:col-span-3 hidden md:block">
                            <div className="grid grid-rows-2 grid-flow-col gap-1">
                                <div className="mx-auto">
                                    <div className="flex items-center">
                                        <div className="flex bg-gray-100 p-1 w-36 md:w-72 space-x-4 rounded text-teal-900">
                                            <MagnifyingGlassIcon width={20}/>
                                            <input className="bg-gray-100 outline-none w-32 md:w-60" id="searchbar" type="text" placeholder="Search Book" onChange={inputChanged}/>
                                            <XMarkIcon width={20} className='cursor-pointer' onClick={clearText}/>
                                        </div>
                                        <div className="px-1">
                                            <button 
                                            className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-900 hover:bg-white mt-0"
                                            onClick={searchBook}
                                            >
                                            Search</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="hidden md:block py-2 mx-auto">
                                    <ul className="flex px-4 mx-auto font-semibold font-heading space-x-12">
                                        <li><a className="flex hover:text-gray-200 text-xs md:text-base focus:underline" href="/">
                                        <HomeIcon width={20} className='mr-0.5'/>Home
                                        </a></li>
                                        <li><a className="flex hover:text-gray-200 text-xs md:text-base focus:underline" href="/">
                                        <BookOpenIcon width={20} className='mr-0.5'/> All Books</a></li>
                                        {isLoggedIn?(
                                            <>
                                        <li><a className="flex hover:text-gray-200 text-xs md:text-base focus:underline" href="/#/my-orders">
                                            <CircleStackIcon width={20} className='mr-0.5'/> My Orders
                                        </a></li>
                                        <li><a className="flex hover:text-gray-200 text-xs md:text-base focus:underline" href="/#/user-account">
                                            <UserCircleIcon width={20} className='mr-0.5'/> My Account
                                        </a></li>
                                        </>
                                        ):null}
                                        <li><a className="flex hover:text-gray-200 text-xs md:text-base focus:underline" href="/#/contact-us">
                                        <PhoneIcon width={20} className='mr-0.5'/> Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-1 lg:col-span-1 md:col-span-1 grid grid-cols-1">
                            <div className="flex items-center justify-end">
                                <div className="hidden md:block mx-4 relative">
                                    <div className="badge w-4 h-4 bg-red-600 text-red-100 absolute rounded-full flex items-center justify-center z-10" style={{fontSize:'smaller',right:'-5px', top:'-12px'}}>{cartItemCount}</div>
                                    <button onClick={goToCart} disabled={!cartItemCount}>
                                        <ShoppingCartIcon width={24}/>
                                    </button>
                                </div>
                                <div className="md:hidden flex items-center">
                                    <div className="mx-auto px-1 relative">
                                        <div className="badge w-4 h-4 bg-red-600 text-red-100 absolute rounded-full flex items-center justify-center z-10" style={{fontSize:'smaller',right:'-5px', top:'-12px'}}>{cartItemCount}</div>
                                        <button onClick={goToCart} disabled={!cartItemCount}>
                                            <ShoppingCartIcon width={24}/>
                                        </button>
                                    </div>
                                    <div className="device-menu">
                                        <button className="mx-2 device-menu-icon" onClick={toggleMenu}><Bars4Icon width={24}/></button>
                                        <div className={`z-50 absolute my-4 text-base list-none bg-teal-500 divide-y divide-gray-100 rounded-lg shadow right-0 top-8 device-menu-dropdown`}>
                                        {isLoggedIn?(
                                            <div className="px-4 py-3">
                                                <span className="block text-sm text-gray-200">{userObj.phone}</span>
                                                {/* <span className="block text-sm  text-gray-100 truncate">name@flowbite.com</span> */}
                                            </div>
                                        ):(null)}
                                            
                                            <ul className="py-2">
                                                <li><a className={deviceMenuClass} href="/">
                                                    <HomeIcon width={20} className='mr-0.5'/>Home
                                                </a></li>
                                                <li><a className={deviceMenuClass} href="/">
                                                    <BookOpenIcon width={20} className='mr-0.5'/> All Books</a></li>
                                                <li><a className={deviceMenuClass} href="/#/contact-us">
                                                    <PhoneIcon width={20} className='mr-0.5'/> Contact Us</a></li>
                                                {isLoggedIn?(
                                                    <>
                                                    <li><a className={deviceMenuClass} href="/#/my-orders">
                                                    <CircleStackIcon width={20} className='mr-0.5'/>My Orders
                                                    </a></li>
                                                    <li><a className={deviceMenuClass} href="/#/user-account">
                                                    <UserCircleIcon width={20} className='mr-0.5'/>My Account
                                                    </a></li>
                                                    <li><a className={deviceMenuClass} href="#" onClick={logoutUser}>
                                                    <ArrowLeftStartOnRectangleIcon width={20} className='mr-0.5'/>Sign Out
                                                    </a></li>
                                                    </>
                                                ):
                                                <>
                                                    <li><a className={deviceMenuClass} href="/#/login">
                                                    <KeyIcon width={20} className='mr-0.5'/>Sign In
                                                    </a></li>
                                                </>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    
                                </div>

                            {isLoggedIn?(
                                <div className="hidden md:block text-gray-200 font-semibold">
                                <UserCircleIcon className="w-6 inline-block"/>
                                <span className="block text-xs md:text-sm">{userObj.phone}</span>
                                <button 
                                className="inline-block text-xs px-1 py-1 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-0"
                                onClick={logoutUser}
                                >
                                    Logout</button>
                                </div>
                            ):(
                                <div className="hidden md:block">
                                    <a href="/#/login" 
                                    className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-900 hover:bg-white mt-0">
                                    Login</a>
                                </div>
                            )}
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="md:hidden flex items-center w-full bg-teal-900 pb-2 px-2">
                    <div className="grid gap-1 mx-auto">
                        <div className="flex items-center mx-auto">
                            <div className="flex bg-gray-100 p-1 space-x-4 rounded text-teal-900">
                                <MagnifyingGlassIcon width={20}/>
                                <input className="bg-gray-100 outline-none w-32" id="deviceSearchbar" type="text" placeholder="Search Book" onChange={inputChanged}/>
                                <XMarkIcon width={20} className='cursor-pointer' onClick={clearText}/>
                            </div>
                            <div className="px-1">
                                <button 
                                className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-900 hover:bg-white mt-0"
                                onClick={searchBook}
                                >
                                Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </header>
    )
}
export default Header;