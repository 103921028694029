import { useState, useRef } from "react";
import {
  Routes,
  Route
} from "react-router-dom";
import { useSelector } from 'react-redux';
import { HashRouter,Navigate} from "react-router-dom";
//import logo from './logo.svg';
import './App.css';
import Loader from './components/common/loader';
import Toaster from './components/common/toaster';
import Modal from './components/common/modal';
import Home from './components/home';
import Dashboard from "./components/dashboard";
import Orders from "./components/dashboard/orders";
import Books from "./components/dashboard/books";
import Categories from "./components/dashboard/categories";
import OtherSettings from "./components/dashboard/other-settings";

//import Users from "./components/dashboard/users";
import PrivateAdminRoutes from "./routes/private-admin-routes";
import Dblogin from './components/dashboard/db-login';
import Login from './components/home/login';
import SetPassword from './components/home/set-password';

import Checkout from './components/home/checkout';
import MyOrders from './components/home/my-orders';
import UserAccount from './components/home/user-account';
import StaticPages from './components/home/static-pages';
import BookDetails from "./components/home/main-view/book-details";
import Maintenance from './components/home/maintenance';
import MyCart from './components/home/my-cart';
import AllBooks from "./components/home/all-books";
// import UserAccount from "./components/home/user-account";
import BookCategories from './components/home/book-categories';
function App() {
    const [loader,toaster] = useSelector((state) => [state.loader,state.toaster]);
    const [loader2, showLoader] = useState(false);
    const [selBook,setSelBook] = useState({});
    const [modal,showModal] = useState(false);
    const opts = useRef({
        title:'Error Occured.',
        msg:'An Error Occured. Please Try Again.',
        action: () => {}
    });
  return (
    <>
      {(loader || loader2)?<Loader/>:null}
      {toaster.show?<Toaster/>:null}
      {modal?(<Modal {...opts.current} showModal={showModal} modal={modal}/>):null}
      <HashRouter>
        <div className="App bg-teal-50">
        <Routes>
          <Route exact 
            path="/" 
            element = {<Home showLoader = {showLoader} showModal={showModal} setSelBook = {setSelBook}/>}>
          </Route>
          
          {/* <Route exact 
            path="/" 
            element = {<Maintenance/>}>
          </Route> */}

          <Route path="login" 
          element={<Login book={selBook} showLoader = {showLoader}  showModal={showModal} opts={opts}/>}> </Route>
          <Route path="set-password" 
          element={<SetPassword book={selBook} showLoader = {showLoader}  showModal={showModal} opts={opts}/>}> </Route>

          <Route path="checkout" 
            element={<Checkout book={selBook} showLoader = {showLoader} showModal={showModal} opts={opts}/>}> </Route>

          <Route path="user-account"
            element={<UserAccount book={selBook} showLoader = {showLoader} showModal={showModal} opts={opts}/>}> </Route>
            
          <Route path="book-details/:bookNameId" 
            element={<BookDetails book={selBook} setSelBook={setSelBook} showLoader = {showLoader} showModal={showModal} opts={opts}/>}> </Route>
          
          <Route path="my-orders" 
            element={<MyOrders showLoader = {showLoader} showModal={showModal} opts={opts}/>}> 
          </Route>

          <Route path="my-cart" 
            element={<MyCart showLoader = {showLoader} showModal={showModal} opts={opts}/>}> 
          </Route>
          <Route path="categories/:catNameId" 
            element={<BookCategories showModal={showModal} setSelBook={setSelBook} opts={opts}/>}> 
          </Route>
          <Route path="book-shelf" 
            element={<AllBooks showModal={showModal} setSelBook={setSelBook} opts={opts}/>}> 
          </Route>

          <Route path="terms-conditions" 
            element={<StaticPages/>}> 
          </Route>
          <Route path="cancellation-and-refund" 
            element={<StaticPages/>}> 
          </Route>
          <Route path="privacy-policy" 
            element={<StaticPages/>}> 
          </Route>
          <Route path="shipping-policy" 
            element={<StaticPages/>}> 
          </Route>
          <Route path="contact-us" 
            element={<StaticPages/>}> 
          </Route>

          <Route element={<PrivateAdminRoutes />}>
            <Route exact path="/dashboard" element = {<Dashboard/>}>
              <Route path="/dashboard" element={<Navigate to="/dashboard/orders" replace={true} />} ></Route>
              <Route path="/dashboard/orders" 
              element={<Orders showLoader = {showLoader}  showErrModal={showModal} opts={opts}/>}
              ></Route>
              <Route path="/dashboard/books" 
              element={<Books showLoader = {showLoader}  showErrModal={showModal} opts={opts}/>}></Route>
              <Route path="/dashboard/categories" 
              element={<Categories showErrModal={showModal} opts={opts}/>}></Route>
              <Route path="/dashboard/other-settings" 
              element={<OtherSettings showErrModal={showModal} opts={opts}/>}></Route>
              <Route path="*" element={<Navigate to="/dashboard/orders" replace={true} />} />
            </Route>
          </Route>

          <Route path="db-login" 
          element={<Dblogin showLoader = {showLoader} showModal={showModal} opts={opts} />} />

           {/* <Route path="db-login" 
          element={<Maintenance showLoader = {showLoader} showModal={showModal} opts={opts} />} /> */}

        </Routes>
        </div>
      </HashRouter>
    </>
  );
}

export default App;
