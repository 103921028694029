import { useState } from 'react';
import axios from "axios";

import {
    KeyIcon,
    PhoneIcon
  } from '@heroicons/react/24/outline';
import CONST from '../../common/constants';
import Logo from "../../common/logo";

const Dblogin = ({showLoader, showModal, opts}) => {
    const [phone,setPhone] = useState('');
    const [password,setPassword] = useState('');

    const adminInfo = JSON.parse(sessionStorage.getItem('adminData')) || {};
    if(adminInfo && adminInfo.guid && adminInfo.guid.length === 36){
       window.location.href = '/#/dashboard';
       return;
    }
    
    const onNumberChange = (e) => {
        setPhone(e.target.value);
    }
    const onPasswordChange = (e) => {
        setPassword(e.target.value);
    }
    const handleSubmit = (e)=>{
        e.preventDefault();
        showLoader(true);
        axios
        .post(`${CONST.apiBase}data.php`, {
          type:'adminLogin',
          password:password,
          mobile:phone
        }).then((resp) => {
          showLoader();
          if(resp.data && resp.data.status === 'success'){
            sessionStorage.setItem(
                "adminData",
                JSON.stringify(resp.data)
              );
              window.location.href = '/#/dashboard';
          }else{
            showLoader();
            opts.current.msg = 'There is an error while login. Please Try again.';
            showModal(true);
            console.error('err:',resp);
          }
        })
        .catch((error) => {
          showLoader();
          console.error('err:',error);
        });
    }
   return (
    <main className="flex items-center justify-center md:h-screen">
      <div className="relative mx-auto flex w-full max-w-[400px] flex-col space-y-2.5 p-4 md:-mt-32">
        <div className="flex h-32 w-full items-center  rounded-lg bg-teal-900 p-3">
          <div className="mx-auto"><Logo/></div>
          
        </div>
        <form className="space-y-3" name="adminLoginForm" onSubmit={handleSubmit} >
      <div className="flex-1 rounded-lg bg-gray-50 px-6 pb-4 pt-8">
        <h1 className={`mb-3 text-2xl`}>
          Please login to continue.
        </h1>
        <div className="w-full">
          <div>
            <label
              className="text-left mb-3 mt-5 block text-xs font-medium text-gray-900"
              htmlFor="userPhone"
            >
              Registered Mobile Number:
            </label>
            <div className="relative">
              <input
                className="peer block w-full rounded-md border border-gray-200 py-[9px] pl-10 text-sm outline-2 placeholder:text-gray-500 disabled:cursor-not-allowed"
                id="userPhone"
                type="tel"
                name="userPhone"
                defaultValue={phone}
                placeholder="Enter 10 Digit Mobile Number"
                onChange={onNumberChange}
                pattern="[0-9]{10}"
                minLength={10}
                maxLength={10}
                required
              />
              <PhoneIcon className="pointer-events-none absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />
            </div>
          </div>

            <div className="mt-4">
            <label
              className="text-left mb-3 block text-xs font-medium text-gray-900"
              htmlFor="userOTP"
            >
              Password:
            </label>
            <div className="relative">
              <input
                className="peer block w-full rounded-md border border-gray-200 py-[9px] pl-10 text-sm outline-2 placeholder:text-gray-500"
                id="userPassword"
                type="password"
                name="userPassword"
                onChange={onPasswordChange}
                placeholder="Enter Password"
                required
              />
              <KeyIcon className="pointer-events-none absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />
            </div>
          </div>
          
        </div>
        
        
          <div className="mt-4">
            <button 
            className="transition ease-in duration-300 inline-flex items-center text-sm font-medium mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600 "
            type="submit"
            >
              <span>Login</span>
            </button>
          </div>
        
        <div className="flex h-8 items-end space-x-1">
          {/* Add form errors here */}
        </div>
      </div>
    </form>
      </div>
    </main>
   )
}
export default Dblogin;