const Advertise = () => {
    return (
        <div className="relative flex flex-col items-center w-full">
          <div className="p-2 md:p-2 m-auto lg:m-2 md:w-2/3 w-92">
            {/* <div className="w-full bg-teal-700 shadow-lg rounded-md p-1">
              <div className="grid grid-cols-5 md:grid-cols-3 gap-1">
                <div className="col-span-2 md:col-span-1">
                  <div className="relative">
                    <img 
                      src={`adds/58698ca5-d98c-4bf3-b43e-d390d5bbdd1d.jpg`}
                      alt="Advertise" className="w-36 h-48 md:w-64 md:h-80 object-fill  rounded-md"
                      />
                  </div>
                </div>
                <div className="col-span-3 md:col-span-2">
                  <div className="flex-auto w-full justify-evenly">
                    <div className="flex flex-wrap p-0.5 md:p-2">
                      <div className="flex w-full justify-between my-2 md:my-2">
                        <h2 className="text-xs md:text-xl text-left text-gray-200 w-full" title="फक्त 2 रुपयात 100 प्रश्नांची प्रश्नपत्रिका">
                        👉फक्त 2 रुपयात 100 प्रश्नांची प्रश्नपत्रिका
                        </h2>
                      </div>
                      <div className="flex w-full justify-between my-2 md:my-2">
                        <h2 className="text-xs md:text-xl text-left text-gray-200 w-full" title="प्रत्येक प्रश्नांचे डिटेल pdf स्पष्टीकरण">
                        👉प्रत्येक प्रश्नांचे डिटेल PDF स्पष्टीकरण
                        </h2>
                      </div>
                      <div className="flex w-full justify-between my-2 md:my-2">
                        <h2 className="text-xs md:text-xl text-left text-gray-200 w-full" title="प्रत्येक प्रश्नांचे डिटेल विडिओ विश्लेषण">
                        👉प्रत्येक प्रश्नांचे डिटेल विडिओ विश्लेषण
                        </h2>
                      </div>
                      <div className="flex w-full justify-between my-2 md:my-2">
                        <h2 className="text-xs md:text-xl text-left text-gray-200 w-full" title="प्रश्नपत्रिका व विश्लेषणाची xerox काढता येते.">
                        👉प्रश्नपत्रिका व विश्लेषणाची xerox काढता येते.
                        </h2>
                      </div>
                    </div>
                      <div className="flex space-x-2 justify-center p-0.5 md:p-8 mx-auto lg:mr-32">
                          <a 
                          href="https://play.google.com/store/apps/details?id=com.maharashtra.academy.pune.app"
                          target="_blank"
                          rel="noreferrer"
                          className="transition ease-in duration-300 inline-flex items-center text-xs md:text-xl mb-2 md:mb-0 bg-purple-500 px-2.5 py-1 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-purple-600"
                          >
                          <span>अँप्लिकेशन डाउनलोड करा</span>
                          </a>
                      </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="w-full md:w-10/12 mx-auto bg-teal-700 shadow-lg rounded-md p-1">
              <a href="https://play.google.com/store/apps/details?id=com.maharashtra.academy.pune.app" 
                target="_blank"
                rel="noreferrer">
                  <img 
                      src={`adds/967c0ac8-795a-473e-985f-fc4be7f7881f.jpeg`}
                      alt="Advertise" className="w-full object-fill  rounded-md"
                    />
              </a>
            </div>
          </div>
        </div>
    )
}
export default Advertise;