import {useState,useEffect} from 'react';
import axios from "axios";
import CONST from "../../../common/constants";
const FlashMessage = () => {
    let flashMsgCalled = false;
    const [flashMsg,setFlashMsg] =  useState('');
    const fetchFlashMsg = () => {
        axios.post(`${CONST.apiBase}dashboard.php`,{
            type:'getFlashMsgData'
            }).then((resp)=>{
                if(resp?.data?.status === 'success'){
                    setFlashMsg(resp.data.flashText);
                }else{
                    console.log('flash msg not available.');
                }
            }).catch((err) => {
                console.log('err:',err);
               
            });
    }
    useEffect(()=>{
        if(!flashMsg && !flashMsgCalled){
            flashMsgCalled = true;
            fetchFlashMsg();
        }
    },[flashMsg,flashMsgCalled]);

    return (
        flashMsg?(
            <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
                <div className="text-center items-center justify-center flex mx-auto">
                    <svg class="fill-current w-16 h-16 md:w-4 md:h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/></svg>
                    <p class="font-bold text-base">
                        {flashMsg}
                    </p>
                </div>
            </div>
        ):null
    );
}
export default FlashMessage;