
import { useLocation } from 'react-router-dom';
import Footer from '../footer';
import Logo from '../../common/logo';
const StaticPages = () => {
    const lastUpdatedOn = 'July 04th 2024';
    const location = useLocation();
    const pathname = location.pathname.replace('/','');
    setTimeout((path)=>{
        document.getElementById(path).scrollIntoView();
    },100,pathname);
    const termsPoints = [
        'The content of the pages of this website is subject to change without notice.',
        'Your use of any information or materials on our website and/or product pages is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through our website and/or product pages meet your specific requirements.',
        'Our website contains material which is owned by or licensed to us. This material includes, but are not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.',
        'All trademarks reproduced in our website which are not the property of, or licensed to, the operator are acknowledged on the website.',
        'Unauthorized use of information provided by us shall give rise to a claim for damages and/or be a criminal offense.',
        'From time to time our website may also include links to other websites. These links are provided for your convenience to provide further information.',
        'You may not create a link to our website from another website or document without MAHABOOK’s prior written consent.',
        'Any dispute arising out of use of our website and/or purchase with us and/or any engagement with us is subject to the laws of India .',
        'We, shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.'
    ];
    const refundPoints = [
        'Cancellations will be considered only if the request is made within same day of placing the order. However, the cancellation request may not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the process of shipping them.',
        'MAHABOOKS does not accept cancellation requests once the shipment is done. However, refund/replacement can be made if the customer establishes that the quality of product delivered is not good.',
        'In case of receipt of damaged or defective items please report the same to our Customer Service team. The request will, however, be entertained once the merchant has checked and determined the same at his own end. This should be reported within same day of receipt of the products.',
        'In case you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within same day of receiving the product. The Customer Service Team after looking into your complaint will take an appropriate decision.',
        'In case of complaints regarding products that come with a warranty from manufacturers, please refer the issue to them.',
        'In case of any Refunds approved by the MAHABOOKS, it’ll take 2-3 working days for the refund to be processed to the end customer.'
    ];
    const privacyPoints = {
        info:[
            'Name',
            'Contact information including email address',
            'Demographic information such as postcode, preferences and interests, if required',
            'Other information relevant to customer surveys and/or offers'
        ],
        use:[
            'Internal record keeping.',
            'We may use the information to improve our products and services.',
            'We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.',
            'From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.'
        ]
    };
    return (
        <div id="staticPages m-0 p-0">
            <div className="w-full mx-auto rounded-2xl shadow-xl bg-teal-200">
                <div className="flex h-20 w-full items-center rounded-lg bg-teal-900 p-3">
                    <div className="p-8"><Logo/></div>
                </div>
            </div>
            <section id="terms-conditions" className="text-slate-500">
                <h2 className="m-5 mt-0 text-2xl font-semibold leading-normal text-left underline underline-offset-8">
                    Terms & Conditions
                </h2>
                <div className="mx-5 mt-1 mb-5 text-left text-sm">
                    <p className="font-bold">
                    Last updated on {lastUpdatedOn}
                    </p>
                    <p className="">
                    For the purpose of these Terms and Conditions, The term "we", "us", "our" used anywhere on this page shall mean MAHABOOKS. "you", “your”, "user", “visitor” shall mean any natural or legal person who is visiting our website and/or agreed to purchase from us.
                    </p>
                    <p className="font-bold">
                    Your use of the website and/or purchase from us are governed by following Terms and Conditions:
                    </p>
                    {termsPoints.map((point,index)=>(
                        <div className="flex my-1 rounded" key={index}>
                            <div className="w-8 h-8 text-center" style={{marginTop:'-5px'}}>
                                <p className="text-2xl p-0 text-green-dark">•</p>
                            </div>
                            <div className="w-4/5 py-1 px-1">
                                <p>{point}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <section id="cancellation-and-refund" className="text-slate-500">
                <h2 className="m-5 text-2xl font-semibold leading-normal text-left underline underline-offset-8">
                    Cancellation & Refund Policy
                </h2>
                <div className="mx-5 mt-1 mb-5 text-left text-sm">
                    <p className="font-bold">
                    Last updated on {lastUpdatedOn}
                    </p>
                    <p className="">
                    MAHABOOKS believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy:
                    </p>
                    {refundPoints.map((point,index)=>(
                        <div className="flex my-1 rounded" key={index}>
                            <div className="w-8 h-8 text-center" style={{marginTop:'-5px'}}>
                                <p className="text-2xl p-0 text-green-dark">•</p>
                            </div>
                            <div className="w-4/5 py-1 px-1">
                                <p>{point}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <section id="privacy-policy" className="text-slate-500">
                <h2 className="m-5 text-2xl font-semibold leading-normal text-left underline underline-offset-8">
                Privacy Policy
                </h2>
                <div className="mx-5 mt-1 mb-5 text-left text-sm">
                    <p className="font-bold">
                    Last updated on {lastUpdatedOn}
                    </p>
                    <p className="my-2">
                    This privacy policy sets out how MAHABOOKS uses and protects any information that you give MAHABOOKS when you visit their website and/or agree to purchase from them.
                    </p>
                    <p className="my-2">
                    MAHABOOKS is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, and then you can be assured that it will only be used in accordance with this privacy statement.
                    </p>
                    <p className="my-2">
                    MAHABOOKS may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you adhere to these changes.
                    </p>
                    <p className="font-bold">
                    We may collect the following information:
                    </p>
                    {privacyPoints.info.map((point,index)=>(
                        <div className="flex my-1 rounded" key={index}>
                            <div className="w-8 h-8 text-center" style={{marginTop:'-5px'}}>
                                <p className="text-2xl p-0 text-green-dark">•</p>
                            </div>
                            <div className="w-4/5 py-1 px-1">
                                <p>{point}</p>
                            </div>
                        </div>
                    ))}
                    <p className="font-bold">
                    What we do with the information we gather
                    </p>
                    <p className="my-2">
                    We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:
                    </p>
                    {privacyPoints.use.map((point,index)=>(
                        <div className="flex my-1 rounded" key={index}>
                            <div className="w-8 h-8 text-center" style={{marginTop:'-5px'}}>
                                <p className="text-2xl p-0 text-green-dark">•</p>
                            </div>
                            <div className="w-4/5 py-1 px-1">
                                <p>{point}</p>
                            </div>
                        </div>
                    ))}
                    <p className="my-2">
                    We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure we have put in suitable measures.
                    </p>
                    <p className="font-bold">
                    How we use cookies
                    </p>
                    <p className="my-2">
                    A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.
                    </p>
                    <p className="my-2">
                    We use traffic log cookies to identify which pages are being used. This helps us analyze data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.
                    </p>
                    <p className="my-2">
                    Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.
                    </p>
                    <p className="my-2">
                    You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.
                    </p>
                    <p className="font-bold">
                    Controlling your personal information
                    </p>
                    <p className="my-2">
                    We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.
                    </p>
                    <p className="my-2">
                    If you believe that any information we are holding on you is incorrect or incomplete, please inform us. We are bound to remove any information from our portal which is related to you.
                    </p>
                </div>
            </section>
            <section id="shipping-policy" className="text-slate-500">
                <h2 className="m-5 text-2xl font-semibold leading-normal text-left underline underline-offset-8">
                Shipping & Delivery Policy
                </h2>
                <div className="mx-5 mt-1 mb-5 text-left text-sm">
                    <p className="font-bold">
                    Last updated on {lastUpdatedOn}
                    </p>
                    <p className="my-2">
                    For International buyers, orders are shipped and delivered through registered international courier companies and/or International speed post only. For domestic buyers, orders are shipped through registered domestic courier companies and /or speed post only. Orders are shipped within 0-7 days or as per the delivery date agreed at the time of order confirmation and delivering of the shipment subject to Courier Company / post office norms. MAHABOOKS is not liable for any delay in delivery by the courier company / postal authorities and only guarantees to hand over the consignment to the courier company or postal authorities within 0-7 days from the date of the order and payment or as per the delivery date agreed at the time of order confirmation. Delivery of all orders will be to the address provided by the buyer. Delivery of our services will be confirmed on your mail ID as specified during registration. For any issues in utilizing our services you may contact our helpdesk
                    </p>
                </div>
            </section>

            <section id="contact-us" className="text-slate-500">
                <h2 className="m-5 text-2xl font-semibold leading-normal text-left underline underline-offset-8">
                    Contact us
                </h2>
                <div className="mx-5 mt-1 mb-5 text-left text-sm">
                    <p className="font-bold">
                    Last updated on {lastUpdatedOn}
                    </p>
                    <div className="flex my-1 rounded">
                        <div className="w-8 h-8 text-center" style={{marginTop:'-5px'}}>
                            <p className="text-2xl p-0 text-green-dark">•</p>
                        </div>
                        <div className="w-4/5 py-1 px-1">
                            <p>
                                <span><strong>Office Address:</strong><br/>
                                    Shop no 3, Shree saishwar society,<br/>
                                    Raman bag chawk, <br/>
                                    Narayan peth, Pune<br/>
                                    Pincode - 411030<br/>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="flex my-1 rounded">
                        <div className="w-8 h-8 text-center" style={{marginTop:'-5px'}}>
                            <p className="text-2xl p-0 text-green-dark">•</p>
                        </div>
                        <div className="w-4/5 py-1 px-1">
                            <p>
                                <span><strong>Mobile:</strong></span><br/>
                                <span>+91 9112 02 1515</span>
                            </p>
                        </div>
                    </div>
                    <div className="flex my-1 rounded">
                        <div className="w-8 h-8 text-center" style={{marginTop:'-5px'}}>
                            <p className="text-2xl p-0 text-green-dark">•</p>
                        </div>
                        <div className="w-4/5 py-1 px-1">
                            <p>
                                <span><strong>Email ID:</strong></span><br/>
                                <span><a href="mailto:mahabooks1@gmail.com" className="hover:underline">mahabooks1@gmail.com</a></span>
                                
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    );
}
export default StaticPages;