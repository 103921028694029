import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";

//import Team, { teamLoader } from "./routes/team";

import Home from '../components/home';
import Dashboard from "../components/dashboard";
import Dblogin from "../components/dashboard/db-login";
import Orders from "../components/dashboard/orders";
import Books from "../components/dashboard/books";
import Users from "../components/dashboard/users";
import PrivateAdminRoutes from './private-admin-routes';
export const router = createHashRouter([
  {
    path: "/",
    element: <Home />,
    //loader: rootLoader,
    // children: [
    //   {
    //     path: "team",
    //     element: <Team />,
    //     loader: teamLoader,
    //   },
    // ],
  },
  {
    path: "/db-login",
    element: <Dblogin />,
    //loader: rootLoader,
    // children: [
    //   {
    //     path: "team",
    //     element: <Team />,
    //     loader: teamLoader,
    //   },
    // ],
  },
  {
    path: "dashboard",
    element: (
        <PrivateAdminRoutes>
            <Dashboard/>
        </PrivateAdminRoutes>
    ),
    //loader: rootLoader,
    children: [
      {
        path: "orders",
        element: <Orders />,
        //loader: teamLoader,
      },
      {
        path: "books",
        element: <Books />,
        //loader: teamLoader,
      },
      {
        path: "users",
        element: <Users />,
        //loader: teamLoader,
      },
      {
        path: "*",
        element: <Users />,
        //loader: teamLoader,
      },
    ],
  },
  {
    path: "*",
    element: <Home />,
  },
]);