import { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { showLoader } from '../middleware/actions';
import CONST from '../components/common/constants';
const useFetch = (endpoint,payload,headers={}) => {
    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    useEffect(()=>{
      if(!data){
        dispatch(showLoader(true));
        (async ()=>{
          const response = await axios.post(`${CONST.apiBase}${endpoint}.php`,payload, headers);
          setData(response.data);
          dispatch(showLoader(false));
        })();
      }
    },[data]);
    return data;
}
export default useFetch;