import axios from "axios";
import { PencilIcon,TrashIcon } from '@heroicons/react/24/solid';
import Checkbox from './checkbox';
import CONST from './../../common/constants';

const getColumns = (books,editBook, showLoader, showErrModal, opts) => {
  const deleteBook = (book) => {
    if(!window.confirm('Do You Really Want To Delete This Book?')){
      return;
    }
    showLoader(true);
    const data = {
      type:'deleteBook',
      bookId:book.bookId
    }
    try {
        axios.post(`${CONST.apiBase}dashboard.php`,data).then((resp)=>{
            showLoader(false);
            opts.current.title = 'Success';
            opts.current.msg = 'Book Deleted Successfully. Refreshing the page....';
            showErrModal(true);
            setTimeout(()=>{
              window.location.reload();
            },1000);
        }).catch((err) => {
            console.log('err:',err);
            showLoader(false);
            opts.current.title = 'Error';
            opts.current.msg = 'An Error Occur. Please Try Again.';
            showErrModal(true);
        });
    } catch (error) {
        console.error(error);
        showLoader(false);
        opts.current.title = 'Error';
        opts.current.msg = 'An Error Occur. Please Try Again.';
        showErrModal(true);
    }
  }
  const columns = [
    {
      id: 1,
      name: "#",
      width:'50px',
      selector: (row) => row.bookIdx
    },
    {
      id: 2,
      name: "Booka Name",
      width:'400px',
      wrap:true,
      cell: (row) =>{
        return <span>{row.bookNameEng}<br/>( {row.bookNameMar} )</span>;
      }
    },
    {
      id: 3,
      name: "MRP",
      selector: (row) => {
        return `₹${row.bookMRP}`
      }
    },
    {
      id: 4,
      name: "SP",
      selector: (row) => {
        return `₹${row.bookSP}`
      }
    },
    {
      id: 5,
      name: "Quantity",
      selector: (row) => {
        return row.bookQty
      }
    },
    {
      id: 5,
      name: "Active",
      cell: (row) =>{
        return <Checkbox row={row} showLoader={showLoader}/>
      }
    },
    {
      id: 5,
      name: "Actions",
      cell: (row) =>{
        return(
          <div className="inline-flex">
            <button title="Edit Book" className="mx-2" onClick={()=>editBook(row)}>
              <PencilIcon width={24} />
            </button>
            <button title="Delete Book" className="mx-2" onClick={()=>deleteBook(row)}>
              <TrashIcon width={24} />
            </button>
          </div>
        ) 
      }
    }
  ];
  return columns;
}
export default getColumns;