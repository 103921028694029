
import { useDispatch } from 'react-redux';
import {CurrencyRupeeIcon, ShoppingCartIcon} from '@heroicons/react/24/solid';
import {calcDiscount} from '../../common/utils';
import {gotoMyCartPage} from '../../../helpers/helpers';
import { addToCart } from '../../../middleware/actions';
const OrderSummary = ({book={},type='summary', setSelBook=()=>{}}) => {
    const dispatch = useDispatch();
    const classes = type === 'book-detail'?
    {container:'w-4/5',imgStyle:{width:'100%',height:'100%'}}:
    {container:'max-w-xs md:max-w-3xl', imgStyle:{width:'211px',height:'255px'}}

    const checkout = () => {
        dispatch(addToCart(book));
        gotoMyCartPage();
    }
    const addToCartClicked = () => {
        dispatch(addToCart(book));
        window.location.href= '/';
    }
    return (
        <div className="flex flex-col justify-center">
            <div
                className={`relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 rounded-xl shadow-lg p-2 ${classes.container} mx-auto border border-teal-500 bg-teal-300`}>
                <div className="w-full md:w-1/3 bg-teal-300 grid place-items-center">
                    <img src={`book-thumbnails/${book.img}`} alt={book.bookNameEng} style={classes.imgStyle} className="rounded-xl" />
                </div>
                <div className="w-full md:w-2/3 flex flex-col space-y-2 p-3">
                    <h3 className="font-black text-gray-800 md:text-3xl text-xl">{book.bookNameEng}</h3>
                    <p className="md:text-lg text-gray-500 text-base">{book.bookDescEng}</p>
                    <div>  
                        <table className="w-full table-auto text-l font-black text-gray-800">
                            <tbody>
                                <tr>
                                <td className="w-1/2"><span className="text-right float-right">MRP:</span></td>
                                <td>₹{book.mrpINR}</td>
                                </tr>
                                <tr>
                                <td className="w-1/2"><span className='text-right float-right'>Discount:</span></td>
                                <td>{calcDiscount(book.mrpINR,book.spINR)}%</td>
                                </tr>
                                <tr>
                                <td className="w-1/2"><span className='text-right float-right'>Delivery Charge:</span></td>
                                <td>Free</td>
                                </tr>
                                <tr>
                                <td className="text-xl w-1/2"><span className='text-right float-right'>Total:</span></td>
                                <td className="text-xl">₹{book.spINR}</td>
                                </tr>
                            </tbody>
                        </table>
                        {type === 'book-detail'?(
                            <div className="flex space-x-2 text-sm font-medium justify-center p-2 m-2 md:m-24">
                                <button 
                                    className="transition ease-in duration-300 inline-flex items-center font-medium bg-purple-500 px-1 py-0.5 hover:shadow-lg tracking-wider text-white  rounded-full hover:bg-purple-600"
                                    onClick={checkout}
                                    >
                                        <CurrencyRupeeIcon width={16} height={16} style={{marginRight:'2px'}}/>
                                        <span>Buy Now</span>
                                    </button>
                                    <button 
                                    className="transition ease-in duration-300 inline-flex items-center font-medium bg-purple-500 px-1 py-0.5 hover:shadow-lg tracking-wider text-white  rounded-full hover:bg-purple-600 "
                                    onClick={addToCartClicked}
                                    >
                                        <ShoppingCartIcon width={16} height={16} style={{marginRight:'2px'}}/> 
                                        <span>Add To Cart</span>
                                    </button>
                            </div>
                        ):null}
                    </div>
                </div>
            </div>
            
        </div>
    )
}
export default OrderSummary;