import Header from './header';
import MainView from './main-view';
import Footer from './footer';
const Home = ({showLoader,setSelBook, showModal}) => {
    return (
        <>
        <Header/>
        <MainView showLoader = {showLoader} setSelBook={setSelBook} showModal={showModal}/>
        <Footer/>
        </>
    )
};
export default Home;