
import { Outlet } from 'react-router-dom';
import DbHeader from "./db-header";
const Dashboard = () => {
    return (
        <div>
            <DbHeader/>
            <div className='overflow-x-hidden overflow-y-hidden'>
                <Outlet />
            </div>
        </div>
        
    )
};
export default Dashboard; 