import { useState } from "react";
import axios from "axios";
import { useDispatch } from 'react-redux';
import CONST from './../../common/constants';
import {showLoader} from '../../../middleware/actions';
const Textbox = ({row}) => {
    const dispatch = useDispatch();

    const [consId, setConsId] = useState((row.consignmentId || null));
    const [valueChanged,setValueChanged] = useState(false);
    const consIdChanged = (e) => {
        setConsId(e.target.value);
        setValueChanged(true);
    }
    const saveConsignment = () => {
        dispatch(showLoader(true));
        axios.post(`${CONST.apiBase}dashboard.php`,{
        type:'updateConsignmentId',
        orderId:row.orderId,
        consId:consId
        }).then((resp)=>{
            if(resp.data.status === 'success'){
                setValueChanged(false);
            }
        }).catch((err) => {
            console.log('err:',err);
        }).finally(()=>{
            dispatch(showLoader(false));
        });
    }
    return (
        <>
            <input type="text" style={{width:'70%',marginRight:'10px', padding:'5px'}} value={consId} placeholder="Consignment Id" onChange={consIdChanged} disabled={row.orderPmtStatus !== '1'}/>
            {valueChanged?(
                <button onClick={()=> saveConsignment(row)} title="Save Consignment Id">
                    <svg 
                    xmlns="http://www.w3.org/2000/svg" fill="none" 
                    viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" 
                    className="h-6 w-6">
                        <path strokeLinecap="round" strokeLinejoin="round" 
                        d="M3 3v18h18V6l-3-3H3ZM7.5 3v6h9V3M6 21v-9h12v9M14.25 5.25v1.5"/>
                    </svg>
                </button>
            ):null}
        </>
    )
}
export default Textbox;