import { useState } from "react";
import axios from "axios";
import CONST from './../../common/constants';
const Checkbox = ({row,showLoader}) => {
    const [checked,setChecked] = useState(row.bookIsActive);
    const bookStatusChanged = (e)=>{
        showLoader(true);
        const value = e.target.checked?'1':'0';
        axios.post(`${CONST.apiBase}dashboard.php`,{
            type:'updateBookStatus',
            bookId:row.bookId,
            active:value
            }).then((resp)=>{
                setChecked(value);
                showLoader(false);
            }).catch((err) => {
                console.log('err:',err);
                showLoader(false);
            });
    }
    return (
        <input type="checkbox" value="active" checked={checked==='1'} onChange={bookStatusChanged}></input>
    )
}
export default Checkbox;