export const getDateTime = (timestamp, localTime) => {
  const dateToProcess = (localTime && localTime!=='0')?parseInt(localTime):timestamp;
    const dt = new Date(dateToProcess);
    let mm = (dt.getMonth()+1);
    mm = mm<10? ('0'+mm):mm;
    const outDate = dt.getDate()+'/'+mm+'/'+dt.getFullYear();

    let hrs = dt.getHours();
    let mins = dt.getMinutes();
    mins = mins<10?('0'+mins):mins;
    const ampm = hrs>=12?'PM':'AM';
    if(hrs>12){
      hrs -= 12;
    }
    const outTime = hrs+':'+mins+ampm;
    
    return outDate+' '+outTime;
}
export const gotoCheckoutPage = () => {
  return;
  const isLoggedIn = sessionStorage.getItem('isLoggedIn') === 'true';
  const userObj = JSON.parse(sessionStorage.getItem('userData')) || {};
  if(!isLoggedIn){
    window.location.href = "/#/login";
    return;
  }
  if(userObj?.hasAddress !== '1'){
    window.location.href = "/#/user-account";
    return;
  }
  window.location.href = "/#/checkout";
}
export const gotoMyCartPage = () => {
  const isLoggedIn = sessionStorage.getItem('isLoggedIn') === 'true';
  const userObj = JSON.parse(sessionStorage.getItem('userData')) || {};
  if(!isLoggedIn){
    window.location.href = "/#/login";
    return;
  }
  if(userObj?.hasAddress !== '1' || !userObj.firstName || !userObj.lastName || !userObj.email){
    window.location.href = "/#/user-account";
    return;
  }
  window.location.href = "/#/my-cart";
}
export const gotoCategoryPage = (catObj) => {
  let catEndpoint = catObj.catNameEn.toLowerCase().replaceAll(' ','-');
  catEndpoint = catEndpoint+'-'+catObj.catId;
  window.location.href = `/#/categories/${catEndpoint}`;
}
export const getAllBookIdsOnPage = (newOrders) => {
  let bookIds = [];
  newOrders.forEach((ordr)=>{
    if(ordr.orderCart){
      const jsonArr = JSON.parse(ordr.orderCart);
      jsonArr.forEach((cartItem)=>{
        if(cartItem.i && bookIds.indexOf(cartItem.i)===-1){
          bookIds.push(cartItem.i);
        }
      })
    }else if(ordr.orderBookId && bookIds.indexOf(ordr.orderBookId) == -1){
      bookIds.push(ordr.orderBookId);
    }
  });
  return bookIds;
}
export const appendBookDetails = (inOrders,inBooks) => {
  inOrders.forEach((inOrder)=>{
    let books = [];
    if(inOrder.orderCart){
      let cart = JSON.parse(inOrder.orderCart);
      cart.forEach((cartItem)=>{
        let book = inBooks.find((b)=>b.bookId === cartItem.i);
        if(book){
          books.push({id:book.bookId,name:book.bookName,qty:cartItem.q});
        }
      })
    }else if(inOrder.orderBookId){
        let book= inBooks.find((b)=>b.bookId === inOrder.orderBookId);
        if(book){
          books.push({id:book.bookId,name:book.bookName,qty:1});
        }
    }
    inOrder.books = books;
    inOrder.id = inOrder.orderId;
  });
}