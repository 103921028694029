import { useState, useEffect, useCallback } from "react";
import { read, utils, writeFileXLSX } from 'xlsx';
import axios from "axios";
import DataTable from "react-data-table-component";
import { ArrowDownIcon, CheckIcon, XMarkIcon, ClockIcon, ArrowPathIcon , DocumentArrowDownIcon} from '@heroicons/react/24/solid';
import CONST from './../../common/constants';
import getColumns from './columns';
import {getAllBookIdsOnPage, appendBookDetails} from '../../../helpers/helpers';
let addressCalled = false;
let stateCalled = false;
let statusesCalled = false;
const Orders = ({showLoader,showErrModal,opts}) => {
  const [orders,setOrders] = useState([]);
  const [columns,setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [states,setStates] = useState({});
  const [addresses,setAddresses] = useState({});
  const [pmtStatus,setPmtStatus] = useState('1');
  const [selectedRows, setSelectedRows] = useState([]);

  const [postFormatSheet,setPostFormatSheet]= useState({});
  const [printFormatSheet,setPrintFormatSheet]= useState({});
  let successOrders = 0;
  let failedOrders = 0;
  let verifiedOrders = 0;
  let nonVerifiedOrders = 0;
  const refreshOrder = (ordrs,index)=>{
    if(index>=ordrs.length){
      showLoader(false);
      opts.current.title= 'Completed.';
      opts.current.msg = `Out of ${ordrs.length},${verifiedOrders} Orders Verified and ${nonVerifiedOrders} Could Not Verified. ${successOrders} Orders Successfull. ${failedOrders} Are Failed.Refreshing the page....`;
      showErrModal(true);
      setTimeout(()=>{
        window.location.reload();
      },3000);
    }else{
      axios.post(`${CONST.apiBase}dashboard.php`,{
        type:'checkPaymentStatus',
        orderId:ordrs[index].orderId
      }).then((resp)=>{
        if(resp &&resp.data && resp.data.paymentStatus){
          verifiedOrders++;
          if(resp.data.paymentStatus ===1){
            successOrders++;
          }else{
            failedOrders++;
          }
        }else{
          nonVerifiedOrders++;
        }
        refreshOrder(ordrs,++index);
      });
    }
  }
  const refreshOrderStatuses = () => {
    showLoader(true);
    verifiedOrders = 0;
    nonVerifiedOrders = 0;
    const payload = {
      type:'getNonVerifiedOrders'
    };
    axios.post(`${CONST.apiBase}dashboard.php`,payload).then((response)=>{
      const nonVeriOrders = response && response.data && response.data.rows;
      if(nonVeriOrders){
        refreshOrder(nonVeriOrders,0);
      }else{
        showLoader(false);
        opts.current.title= 'Completed.';
        opts.current.msg = response?.data?.msg || "No Pending Orders.";
        showErrModal(true);
      }
    });
  }
  const getAddress = (r) => {
    return (`${r.orderAddress}, ${r.orderTownCity},\r\nTal - ${r.orderTaluka},\r\nDist - ${r.orderDist},\r\nPincode - ${r.orderPincode},\r\nState - ${r.orderState},\r\nMobile - ${r.orderPhone}`);
  }
  const getAddressForPost = (r) => {
    return (`${r.orderAddress}, ${r.orderTownCity},\r\nTal - ${r.orderTaluka}`);
  }
  const getBookString= (books) =>{
    let bookStr = '';
    if(books){
      books.forEach((bk)=>{
        bookStr+=(`${bk.name} - ${bk.qty},\r\n`);
      });
    }
    return bookStr;
  }
  const formatDataForExcel = () => {
    let outData = [];
    let inData = [...selectedRows];
    inData.sort((a,b)=> a.orderIdx-b.orderIdx);
    inData.forEach((row, idx)=>{
      const bookStr = getBookString(row.books);
      let arr= [
        (idx+1),
        '',
        row.orderFullName,
        getAddressForPost(row),
        row.orderPincode,
        '',
        '',
        row.orderDist,
        '',
        bookStr
      ];
      outData.push(arr);
    });
    return outData;
  }
  const exportToExcel = () => {
    const today = new Date();
    const dateStr = `${today.getDate()}/${today.getMonth()+1}/${today.getFullYear()}`;

    const fileName = 'orders-for-post-'+(dateStr.replaceAll('/','-'));
    const rows = formatDataForExcel();
    utils.sheet_add_aoa(postFormatSheet, rows, {origin: "A7"});
    const wb = utils.book_new();
    utils.book_append_sheet(wb, postFormatSheet, "Mahabooks Orders");
    writeFileXLSX(wb, `${fileName}.xlsx`);
    setTimeout((date)=>{
      exportToPrint(date);
    },2*1000,dateStr);
  }

  const formatDataInPrintFormat = (dateStr) => {
    let outData = [];
    let inData = [...selectedRows];
    inData.sort((a,b)=> a.orderIdx-b.orderIdx);
    inData.forEach((row, idx)=>{
      const bookStr = getBookString(row.books);
      let arr = [
        (idx+1),
        (`To,\r\n${row.orderFullName},\r\n${getAddress(row)}`),
        bookStr,
        dateStr
      ];
      outData.push([]);
      outData.push(arr);
    });
    return outData;
  }

  const exportToPrint = (dateStr) => {
    const fileName = 'orders-to-print-'+(dateStr.replaceAll('/','-'));
    const rows = formatDataInPrintFormat(dateStr);
    utils.sheet_add_aoa(printFormatSheet, rows, {origin: "A1"});

    const wb = utils.book_new();
    utils.book_append_sheet(wb, printFormatSheet, "Mahabooks Orders");
    writeFileXLSX(wb, `${fileName}.xlsx`);
  }
  const fetchOrders = async (_page, _perPage) => {
    setLoading(true);
    showLoader(true);
    const payload = {
      type:'getPagewiseOrders',
      page:_page,
      perPage:_perPage,
      pmtStatusId:pmtStatus
    };
    const response = await axios.post(`${CONST.apiBase}dashboard.php`,payload);
    setLoading(false);
    showLoader(false);
    if(response.data.status === 'success'){
      const newOrders = appendAddress(response.data.orders);
      const booksOnPage = getAllBookIdsOnPage(newOrders);
      const bookListResp = await axios.post(`${CONST.apiBase}dashboard.php`,{type:'getBooksByIdList',ids:booksOnPage.join(',')});
      if(bookListResp && bookListResp.data && bookListResp.data.rows){
        appendBookDetails(newOrders,bookListResp.data.rows);
      }
      setOrders(newOrders);
      setTotalRows(response.data.totalOrders);
    }else{
      opts.current.title = 'Error';
      opts.current.msg = 'An Error Occured.';
      showErrModal(true);
    }
  };

  const handlePageChange = page => {
    fetchOrders(page, perPage);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(50);
    fetchOrders(page,newPerPage);
  };
  const appendAddress = (inOrders) => {
    let ordersArray = inOrders.slice();
    ordersArray.forEach((order)=> {
      if(order.orderAddressId){
        const add = addresses[order.orderAddressId];
        if(add){
          if(!order.orderFullName){
            order.orderFullName = `${add.firstName} ${add.lastName}`;
          }
          if(!order.orderPhone){
            order.orderPhone = add.mobile;
          }
          
          order.orderAddress = add.flatNo.trim();
          order.orderTownCity = add.village.trim();
          order.orderTaluka = add.taluka.trim();
          order.orderDist = add.dist.trim();
          order.orderPincode = add.pincode;
          if(states[add.state]){
            order.orderState = states[add.state].stateNameEn;
          }
        }
      }else if(!order.orderState && order.orderStateId && states[order.orderStateId]){
        order.orderState = states[order.orderStateId].stateNameEn;
      }
    })
    return ordersArray;
  }
  const getOrderStatuses = async() => {
    const response = await axios.post(`${CONST.apiBase}data.php`,{
        type:'getOrderStatuses'
      });
      if(response && response.data.status === 'success'){
        setColumns(getColumns(response.data.rows, showLoader,showErrModal,opts));
      }else{
        console.log('err occured.');
      }
  }
  const getAllAddresses = async() => {
    const response = await axios.post(`${CONST.apiBase}dashboard.php`,{
        type:'getAllAddresses'
      });
      if(response && response?.data?.addresses.length){
        const addObj = {};
        response.data.addresses.forEach((add)=>{addObj[add.id]=add});
        setAddresses(addObj);
      }else{
        console.log('err occured.');
      }
  }
  const getStates = useCallback(()=> {
      showLoader(true);
      axios
        .get(`data/states.json`)
        .then((response) => {
          showLoader(false);
          if (response.data && response.data.length) {
            const stateObj = {};
            response.data.forEach((state)=>{stateObj[state.stateId]=state})
            setStates(stateObj);
          }
        })
        .catch((error) => {
          showLoader(false);
          console.error(error);
        });
  },[])
  const getExcelFormats = async()=> {
    const print = await (await fetch("data/format-for-print.xlsx")).arrayBuffer();
    const wbPrint = read(print);
    const wsPrint = wbPrint.Sheets[wbPrint.SheetNames[0]];
    setPrintFormatSheet(wsPrint);

    const post = await (await fetch("data/format-for-post.xlsx")).arrayBuffer();
    const wbPost = read(post);
    const wsPost = wbPost.Sheets[wbPost.SheetNames[0]];
    setPostFormatSheet(wsPost);
  };

  useEffect(() => {
    if(!statusesCalled){
      statusesCalled = true;
      getOrderStatuses();
    }
    if(!addressCalled){
      addressCalled = true;
      getAllAddresses();
    }
    if(!stateCalled){
      stateCalled = true;
      getStates();
      getExcelFormats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if(Object.keys(states).length && Object.keys(addresses).length){
      setSelectedRows([]);
      fetchOrders(1, perPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pmtStatus, states, addresses]);
  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);
  
  return (
    <>
      <div className="my-4 w-full">
        <button 
        className={`transition ease-in duration-300 inline-flex items-center text-sm font-medium mx-1 mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600 ${pmtStatus === '1'?'bg-teal-700':null}`}
        type="button"
        onClick = {()=>setPmtStatus('1')}
        >
          <CheckIcon width={16} height={16} style={{marginRight:'5px'}}/><span>Success</span>
        </button>
        <button 
        className={`transition ease-in duration-300 inline-flex items-center text-sm font-medium mx-1 mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600 ${pmtStatus === '0'?'bg-teal-700':null}`}
        type="button"
        onClick = {()=>setPmtStatus('0')}
        >
          <ClockIcon width={16} height={16} style={{marginRight:'5px'}}/>
          <span>Pending</span>
        </button>
        <button 
        className={`transition ease-in duration-300 inline-flex items-center text-sm font-medium mx-1 mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600 ${pmtStatus === '2'?'bg-teal-700':null}`}
        type="button"
        onClick = {()=>setPmtStatus('2')}
        >
          <XMarkIcon width={16} height={16} style={{marginRight:'5px'}}/>
          <span>Failed</span>
        </button>
        <button 
        className="transition ease-in duration-300 inline-flex items-center text-sm font-medium mx-1 mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600 float-right"
        type="button"
        onClick = {()=>refreshOrderStatuses()}
        >
          <ArrowPathIcon width={16} height={16} style={{marginRight:'5px'}}/>
          <span>Refresh Orders</span>
        </button>
        {pmtStatus === '1'?(
          <button 
          className="transition ease-in duration-300 inline-flex items-center text-sm font-medium mx-1 mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg disabled:hover:shadow-none tracking-wider text-white rounded-full disabled:hover:bg-teal-500 hover:bg-teal-600 float-right disabled:cursor-not-allowed"
          type="button"
          onClick = {()=>exportToExcel()}
          disabled={!selectedRows.length}
          >
            <DocumentArrowDownIcon width={16} height={16} style={{marginRight:'5px'}}/>
            <span>Export to Excel</span>
          </button>
        ):null}
      </div>
      <DataTable
          title="Orders"
          columns={columns}
          data={orders}
          defaultSortFieldId={1}
          sortIcon={<ArrowDownIcon />}
          progressPending={loading} 
          pagination 
          selectableRows
          onSelectedRowsChange={handleRowSelected}
          paginationServer 
          paginationTotalRows={totalRows} 
          onChangeRowsPerPage={handlePerRowsChange} 
          onChangePage={handlePageChange}
      />
      <iframe id="iframeToPrint" width= '1' height='1' style={{visibility:'hidden'}}></iframe>
    </>
    
  )
};
export default Orders;