
import { useState,useEffect } from "react";
import {useParams} from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import Book from '../main-view/book';
import useFetch from '../../../helpers/useFetch';
const BookCategories = ({setSelBook}) => {
    const [books,setBooks] = useState([]);
    const [catObj,setCatObj] = useState({catNameEn:'',catNameMr:''});
    const {catNameId} = useParams();
    const catNameIdArr = catNameId.split('-');
    const categoryId = catNameIdArr[catNameIdArr.length-1];
    const bookData = useFetch('data',{type:'getBooksByCategory',catId:categoryId});
    useEffect(()=>{
        if(!books.length && bookData && bookData.rows){
            setBooks(bookData.rows);
            setCatObj({catNameEn:bookData.catNameEn,catNameMr:bookData.catNameMr});
        }
    },[books,bookData])
    return (
        <>
        <Header/>
        <div className="flex w-full min-h-fit flex-wrap sm:w-fit">
            <div className="mx-4 mt-8 mb-2 inline-flex w-full font-bold">
                <h1 className="text-lg md:text-2xl uppercase w-3/4 text-left">{catObj.catNameEn} | {catObj.catNameMr}</h1>
            </div>
            {books.map((book)=>(
                <Book key={book.bookId} book={book} setSelBook = {setSelBook}/>
            ))}
        </div>
        <Footer/>
        </>
    );
};
export default BookCategories;