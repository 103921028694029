import Header from "../header";
import Footer from "../footer";
import Sidebar from "../sidebar";
const AllBooks= () => {
    return (
        <>
        <Header/>
        <div className="flex w-full min-h-fit flex-wrap">
            <div className="left-sidebar" style={{width:'200px'}}>
                <Sidebar/>
            </div>
            <div className="right-content" style={{width:'calc(100% - 200px)'}}>content</div>
            {/* <div className="mx-4 mt-8 mb-2 inline-flex w-full font-bold">
                <h1 className="text-lg md:text-2xl uppercase w-3/4 text-left">{catObj.catNameEn} | {catObj.catNameMr}</h1>
            </div>
            {books.map((book)=>(
                <Book key={book.bookId} book={book} setSelBook = {setSelBook}/>
            ))} */}
        </div>
        <Footer/>
        </>
    );
};
export default AllBooks;