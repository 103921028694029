import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useDispatch } from 'react-redux';
import { PencilIcon,TrashIcon } from '@heroicons/react/24/solid';
import CONST from '../../common/constants';
import {showLoader} from '../../../middleware/actions';
import AddEditCategory from './add-edit-category';
const Categories = ({showErrModal,opts}) => {
    const dispatch = useDispatch();
    const [cats,setCats] = useState([]);
    const [selectedCat,setSelectedCat] = useState([]);
    const [showModal,setShowModal] = useState(false);
    const apiCalled = useRef(false);
    const getCategories = async() => {
        dispatch(showLoader(true));
        const response = await axios.post(`${CONST.apiBase}dashboard.php`,{
          type:'getCategories'
        });
        dispatch(showLoader(false));
        if(response.data.status === 'success'){
            setCats(response.data.rows);
        }else{
        //   opts.current.msg = response?.data?.msg;
        //   showErrModal(true);
        }
    }
    const addNewCategory = () => {
        const category = {
            catId:'',
            catNameEn:'',
            catNameMr:'',
            catPriority:'',
            catActive:'',
        };
        setSelectedCat(category);
        setShowModal(true);
    }
    const editCategory = (category) => {
        setSelectedCat(category);
        setShowModal(true);
    }
    const deleteCategory = (category) => {
        if(!window.confirm('Do You Really Want To Delete This Category?')){
            return;
        }
        dispatch(showLoader(true));
        const data = {
            type:'deleteCategory',
            categoryId:category.catId
        }
        try {
            axios.post(`${CONST.apiBase}dashboard.php`,data).then((resp)=>{
                dispatch(showLoader(false));
                opts.current.title = 'Success';
                opts.current.msg = 'Category Deleted Successfully. Refreshing the page.....';
                showErrModal(true);
                setTimeout(()=>{
                    window.location.reload();
                },1000);
            }).catch((err) => {
                console.log('err:',err);
                dispatch(showLoader(false));
                opts.current.title = 'Error';
                opts.current.msg = 'An Error Occur. Please Try Again.';
                showErrModal(true);
            });
        } catch (error) {
            console.error(error);
            dispatch(showLoader(false));
            opts.current.title = 'Error';
            opts.current.msg = 'An Error Occur. Please Try Again.';
            showErrModal(true);
        }
    }
    useEffect(()=>{
        if(!cats.length && !apiCalled.current){
            apiCalled.current = true;
            getCategories();
        }
    },[])
    return (
        <>
            <div className="py-2 float-right">
                <button 
                    className="transition ease-in duration-300 inline-flex items-center text-sm font-medium mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600 "
                    type="button"
                    onClick={addNewCategory}
                    >
                    <span>+ Add New Category</span>
                </button>
            </div>
            <div style={{height:'calc(100%-140px)'}}>
                <section className="antialiased bg-gray-100 text-gray-600 h-screen px-4">
                    <header className="px-5 py-4 border-b border-gray-100">
                        <h2 className="font-semibold text-gray-800">Categories</h2>
                    </header>
                    <div className="p-3">
                        <div className="overflow-x-auto">
                            <table className="table-auto w-full">
                                <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                                    <tr>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">#</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Name (English)</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Name (Marathi)</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Priority</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Active</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Actions</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="text-sm divide-y divide-gray-100">
                                    {cats.map((category, index)=>(
                                        <tr key={index}>
                                            <td className="p-2 whitespace-nowrap">
                                                <div className="text-center">{index+1}</div>
                                            </td>
                                            <td className="p-2 whitespace-nowrap">
                                                <div className="text-center">{category.catNameEn}</div>
                                            </td>
                                            <td className="p-2 whitespace-nowrap">
                                                <div className="text-center">{category.catNameMr}</div>
                                            </td>
                                            <td className="p-2 whitespace-nowrap">
                                                <div className="text-center">{category.catPriority}</div>
                                            </td>
                                            <td className="p-2 whitespace-nowrap">
                                                <div className="text-center">{category.catActive}</div>
                                            </td>
                                            <td className="p-2 whitespace-nowrap">
                                                <div className="text-center">
                                                    <div className="inline-flex">
                                                        <button title="Edit Category" className="mx-2" onClick={()=>editCategory(category)}>
                                                        <PencilIcon width={24} />
                                                        </button>
                                                        <button title="Delete Category" className="mx-2" onClick={()=>deleteCategory(category)}>
                                                        <TrashIcon width={24} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </div>
            
            {showModal?<AddEditCategory category={selectedCat} setShowModal={setShowModal} opts={opts} showErrModal={showErrModal}/>:null}
        </>
    );
}
export default Categories;
