import axios from "axios";
 import { useDispatch } from 'react-redux';
import { PrinterIcon, EnvelopeIcon, ArrowPathIcon,
  CheckCircleIcon,XCircleIcon,ExclamationTriangleIcon
 } from '@heroicons/react/24/solid';
import CONST from '../../common/constants';
import Dropdown from "./dropdown";
import Textbox from "./textbox";
// import {showLoader} from '../../../middleware/actions';
import {getDateTime} from '../../../helpers/helpers';
import { printOrder } from "./utils";
const getColumns = (statuses, showLoader,showModal,opts)=>{
 
  const refreshPmtStatus = async (row) => {
    showLoader(true);
    const response = await axios.post(`${CONST.apiBase}dashboard.php`,{
      type:'checkPaymentStatus',
      orderId:row.orderId
    });
    if(response?.data?.status === 'success'){
      opts.current.title = 'Success.';
      opts.current.msg = response?.data?.msg;
    }else{
      opts.current.title = 'Error';
      opts.current.msg = response?.data?.msg;
    }
    showLoader(false);
    showModal(true);
  }
  const sendMessage = async (row)=>{
    showLoader(true);
    const response = await axios.post(`${CONST.apiBase}dashboard.php`,{
      type:'sendOrderMsgToUser',
      orderId:row.orderId,
      mobile:row.orderPhone
    });
    if(response?.data?.status === 'success'){
      opts.current.title = 'Message Sent.';
      opts.current.msg = response?.data?.msg;
    }else{
      opts.current.title = 'Error';
      opts.current.msg = response?.data?.msg;
    }
    showLoader(false);
    showModal(true);
  }
  
  return  [
    {
      id: 1,
      name: "#",
      width:'50px',
      selector: (row) => row.orderIdx
    },
    {
      id: 2,
      name: "Order #",
      width:'100px',
      selector: (row) => `MHBO${row.orderId}`
    },
    {
      id: 3,
      name: "Name, Address & Phone",
      wrap:true,
      cell: (row) =>{
        return <span style={{textAlign:'left'}}>
          {row.orderFullName}<br/>
          {row.orderAddress}, {row.orderTownCity},<br/>
          Tal - {row.orderTaluka}, Dist - {row.orderDist},<br/>
          Pincode - {row.orderPincode}<br/>
          State - {row.orderState}<br/>
          Mobile - {row.orderPhone}
        </span>
      }
    },
    {
      id: 4,
      name: "Order Items",
      wrap:true,
      width:'250px',
      selector: (row) => {
        return (
          (row.books && row.books.length)?( 
          <table 
          className='book-table'
          ><thead><tr><th className="w-2">SR</th><th>Name</th><th className="w-2">Qty</th></tr></thead><tbody>
            {row.books.map((book,idx)=><tr key={book.id}><td>{idx+1}</td><td>{book.name}</td><td>{book.qty}</td></tr>)}
          </tbody></table>
          ):(<span>-</span>)
        );
      }
    },
    {
      id: 5,
      name: "Date & Time",
      selector: (row) => {
        return getDateTime(row.orderDateTime,row.orderLocalTime);
      },
      sortable: true
    },
    {
      id: 6,
      name: "Payment",
      width:'100px',
      selector: (row) => {
        return(
          row.orderPmtStatus==='1'
          ?(<CheckCircleIcon width={24} className="text-green-600" title="Success"/>)
          :(row.orderPmtStatus==='2'
            ?(<XCircleIcon width={24} className="text-red-600" title="Failed"/>)
            :(<ExclamationTriangleIcon width={24} className="text-yellow-500" title="Pending"/>))
        );
      }
    },
    {
      id: 7,
      name: "Consignment Id",
      cell: (row) =>{
        return <Textbox row={row}/>
      }
    },
    {
      id: 8,
      name: "Order Status",
      cell: (row) =>{
        return <Dropdown statuses={statuses} row={row}/>
      }
    },
    {
      id: 9,
      name: "Actions",
      cell: (row) =>{
        return (
          <>
          {row.orderPmtStatus === '2'?null:(
            <button>
              <PrinterIcon
              width={24}
              className="mx-2"
              title='Print Order Details'
              onClick={()=>printOrder(row)}
              />
            </button>
          )}
            {row.orderStatus !== '1'?(
              <button>
                <EnvelopeIcon
                width={24}
                className="mx-2"
                title='Send Message To User'
                onClick={()=>sendMessage(row)}
                />
              </button>
            ):null}
              <button>
                <ArrowPathIcon
                width={24}
                className="mx-2"
                title='Refresh Payment Status'
                onClick={()=>refreshPmtStatus(row)}
                />
              </button>
            
          </>
        )
      }
    }
];
}

export default getColumns;