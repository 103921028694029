export const printOrder = (row) => {
    const html = `<table style=width:100%>
    <tr>
    <th style="width:60%;text-align:left;">To,</th>
    <th style="width:40%;text-align:left;">From,</th>
    </tr>
    <tr>
    <td>
    <span>${row.orderFullName}</span><br><br>
    <span><strong>Address:</strong><br>${row.orderAddress},${row.orderTownCity}<br>
    Tal - ${row.orderTaluka}, Dist - ${row.orderDist},<br>
    Pincode - ${row.orderPincode}<br>
    State - ${row.orderState}</span><br><br>
    <span><strong>Mobile: </strong>${row.orderPhone}</span>
    </td>
    <td>
    <span>Mahabooks</span><br><br>
    <span><strong>Address:</strong><br>
    Shop no 3, Shree saishwar society,<br>
    Raman bag chawk, <br>
    Narayan peth, Pune </span><br>
    Pincode - 411030<br><br>
    <span><strong>Mobile: </strong>9112 02 1515</span>
    </td>
    </tr>
    </table>
    <br/>
    <br/>
    <div style="text-align:center;font-size:smaller;">${row.bookName}</div>
    `;
    const iframe = document.getElementById('iframeToPrint');
    iframe.srcdoc = html;
    iframe.onload = () => {
        iframe.contentWindow.print();
    };
}