import { useState,useEffect } from "react";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { UserCircleIcon } from "@heroicons/react/24/solid";
import CONST from '../../common/constants';
import {showLoader} from '../../../middleware/actions';

const Reviews = ({bookId}) => {
    const dispatch = useDispatch();
    let reviewCalled = false;
    const [reviewData,setReviewData] = useState({name:'',review:''});
    const [booksReviews,setBooksReviews] = useState([]);
    const userObj = JSON.parse(sessionStorage.getItem('userData')) || {};

    const submitReview = async () => {
        dispatch(showLoader(true));
        const response = await axios.post(`${CONST.apiBase}data.php`,{
            type:'submitReview',
            userId:userObj.userId,
            review:reviewData.review,
            bookId:bookId,
            key:(new Date().getTime())
        });
        dispatch(showLoader(false));
        reviewCalled = false;
        getBookReviews();
    }
    const getBookReviews = async () => {
        const response = await axios.post(`${CONST.apiBase}data.php`,{
            type:'getBookReviews',
            bookId:bookId
        });
        console.log('>>>> response:', response);
        if(response && response.data && response.data.rows){
            setBooksReviews(response.data.rows);
        }
    }
    useEffect(()=>{
        if(bookId && !reviewCalled){
            reviewCalled = true;
            getBookReviews();
        }
    },[bookId])

    const setUserReview = (e) => {
        const temp  = {...reviewData};
        temp.review = e.target.value;
        setReviewData(temp);
    };
    // const setUserName = (e) => {
    //     const temp  = {...reviewData};
    //     temp.review = e.target.value;
    //     setReviewData(temp);
    // }
    const parseRevDate = (revTime)=>{
        if(!revTime){
            return '';
        }
        let dateObj = new Date(parseInt(revTime));
        return `${dateObj.getDate()}/${dateObj.getMonth()+1}/${dateObj.getFullYear()}`;
    }
    console.log('>>> booksReviews:', booksReviews);
    return (
        <section className="py-2 relative">
            <div className="w-full max-w-7xl px-4 md:px-5 lg-6 mx-auto">
                <div className="w-full">
                    <h2 className="font-manrope font-bold text-4xl text-black mb-8 text-center">Our customer reviews
                    </h2>
                    {userObj.userId?(
                        <div className="border border-teal-500 bg-teal-200 rounded w-full md:w-1/3 p-4">
                            <h4 className="font-manrope font-bold text-xl text-black mb-2 text-left">Submit Your review
                            </h4>
                            <div className="pt-5 max-xl:max-w-2xl max-xl:mx-auto">
                                <div className="flex sm:items-center flex-col min-[400px]:flex-row justify-between gap-5 mb-4">
                                    <div className="flex items-center gap-3">
                                        <label htmlFor="userName" className="w-20" >Name:</label>
                                        <input name="userName" type="text" className="font-semibold text-base leading-6 w-64 p-2" value={`${userObj.firstName} ${userObj.lastName}`} placeholder="Name" disabled/>
                                    </div>
                                </div>
                                <div className="flex sm:items-center flex-col min-[400px]:flex-row justify-between gap-5 mb-4">
                                    <div className="flex items-center gap-3">
                                        <label htmlFor="userReview" className="w-20">Review:</label>
                                        <textarea name="userReview" value={reviewData.review} onChange={setUserReview} className="font-semibold text-base leading-6 w-64 p-2" placeholder="Enter Your Review About this book." maxlength="250"></textarea>
                                    </div>
                                </div>
                                <div className="flex space-x-2 text-sm font-medium justify-end p-2 m-2">
                                    <button 
                                    className="transition ease-in duration-300 inline-flex items-right text-sm font-medium mb-2 md:mb-0 bg-purple-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-purple-600"
                                    disabled={!reviewData.review}
                                    onClick={submitReview}
                                    >
                                        <span>Submit</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ):null}
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-8">
                    {booksReviews.map((review)=>(
                        <div key={`rev${review.revId}`} className="my-4">
                            <div className="border border-teal-500 rounded bg-teal-200 ml-0 mr-8 p-4 ">
                                <div className="flex sm:items-center flex-row justify-between gap-5 mb-4">
                                    <div className="flex items-center gap-3">
                                        <UserCircleIcon className="w-8 h-8 rounded-full"/>
                                        <h6 className="font-semibold text-lg leading-8 text-indigo-600 ">{review.revUserName}</h6>
                                    </div>
                                    <p className="font-semibold text-base leading-8 text-left">{parseRevDate(review.revTime)}</p>
                                </div>
                                <p className="font-semibold text-base leading-8 text-left">{review.revText}</p>
                            </div>
                            
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </section>                                   
    );
}
export default Reviews;