import axios from "axios"; 
import { useDispatch } from 'react-redux';
import {showLoader} from '../../../middleware/actions';
import CONST from "../../common/constants";
import Logo from '../../common/logo';
import BackToHome from '../back-to-home';
import OrderSummary from '../order-summary';
import {useState, useEffect,useCallback} from 'react'; 
const Checkout = ({book, showLoader1, showModal, opts})=>{
  const dispatch = useDispatch();
  const formAction = `${CONST.apiBase}rzp-payments.php`;
  const isLoggedIn = sessionStorage.getItem('isLoggedIn') === 'true';
  const userObj = JSON.parse(sessionStorage.getItem('userData')) || {};
  const [states, setStates] = useState([]);
  const [stateValue, setStateValue] = useState((userObj.stateId||CONST.defaultStateId));
  const [addressId,setAddressId] = useState('');
  const [addresses, setAddresses] = useState([]);
  const [userTempObj,setUserTempObj] = useState({});
  const getStates = useCallback(()=> {
    dispatch(showLoader(true));
    axios
      .get(`data/states.json`)
      .then((response) => {
        dispatch(showLoader(false));
        if (response.data && response.data.length) {
          setStates(response.data);
        }else {
          opts.current.msg = 'An Error Occured.';
          showModal(true);
        }
      })
      .catch((error) => {
        dispatch(showLoader(false));
        console.error(error);
      });
  },[]);

  const getAddresses = useCallback(()=> {
    dispatch(showLoader(true));
    axios
      .post(`${CONST.apiBase}data.php`, {
        type:'getAddresses',
        userId:userObj.userId
      })
      .then((response) => {
        dispatch(showLoader(false));
        if (response.data.status === "success") {
          const defAddress = response.data.addresses.find((add)=>add.isDefault === '1');
          if(defAddress){
            setAddressId(defAddress.id);  
            setUserTempObj({name:defAddress.firstName+' '+defAddress.lastName,email:defAddress.email});
          }else{
            let firstAddress = response.data.addresses[0];
            if(firstAddress){
              setAddressId(firstAddress.id);
              setUserTempObj({name:firstAddress.firstName+' '+firstAddress.lastName,email:firstAddress.email});
            }
          }
          setAddresses(response.data.addresses);
        } else {
          opts.current.msg = response.data.message;
          showModal(true);
        }
      })
      .catch((error) => {
        dispatch(showLoader(false));
        console.error(error);
      });
  },[])
  const getStateById = (stateId)=> {
    let stateObj = states.find((state)=>state.stateId === stateId);
    if(!stateObj || !stateObj.stateNameEn){
      stateObj = states.find((state)=>state.stateId === CONST.defaultStateId)
    }
    return stateObj?.stateNameEn;
  }
  const isReqAvailable = (isLoggedIn && userObj?.hasAddress === '1' && book && book.bookId);
  useEffect(()=>{
    if(!states.length && isReqAvailable){
      getStates();
    }
  },[states, getStates]);

  useEffect(()=>{
    if(!addresses.length && isReqAvailable){
      getAddresses();
    }
  },[addresses, getAddresses, isLoggedIn, userObj, book]);
  const addressChanged = (e) => {
    setAddressId(e.target.value);
    const currentAdd = addresses.find((ad)=> (ad.id = e.target.value));
    setUserTempObj({name:currentAdd.firstName+' '+currentAdd.lastName,email:currentAdd.email});
  }
  if(!isLoggedIn){
    window.location.href = "/#/login";
    return;
  }
  if(userObj?.hasAddress !== '1'){
    window.location.href = "/#/manage-address";
    return;
  }
  if(!(book && book.bookId)){
    window.location.href = "/";
    return;
  }
  return (
        <section className="bg-teal-100">
          <div className="flex justify-center items-center">
            <div className="container mx-auto px-4 lg:px-20 justify-center items-center">
              <form name="checkoutForm" method="POST" action={formAction}>
                <div className="w-full p-4 my-4 mx-auto lg:w-9/12 rounded-2xl shadow-2xl bg-teal-200">
                  <div className="flex h-32 w-full items-center rounded-lg bg-teal-900 p-3">
                    <div className="mx-auto p-8"><Logo/></div>
                  </div>
                  <div className="flex">
                    <h2 className="font-bold uppercase lg:text-2xl text-base my-1">Order Summary</h2>
                  </div>
                  <div className="grid grid-cols-1 gap-5 mt-5">
                    <OrderSummary book = {book}/>
                  </div>
                  <div className="flex">
                    <h2 className="font-bold uppercase lg:text-2xl text-base mt-2">Billing Details</h2>
                  </div>
                  <div>
                    <input name="type" type="hidden" value="getOrderId"/>
                    <input name="userId" type="hidden" value={userObj.userId}/>
                    <input name="uBookId" type="hidden" value={book.bookId}/>
                    <input name="mobile" type="hidden" value={userObj.phone}/>
                    <input name="name" type="hidden" value={userTempObj.name}/>
                    <input name="email" type="hidden" value={userTempObj.email}/>
                    <input name="timestamp" type="hidden" value={new Date().getTime()}/>
                  </div> 
                  <div className="flex">
                    <h3 className="font-semibold text-base mt-2">Please Select Your Delivery Address</h3>
                  </div>
                  <div className="grid grid-cols-1 gap-5 mt-5">
                    {addresses.map((address)=>(
                      <div key={address.id} className="flex items-center mb-2">
                        <input id={`address-${address.id}`} name="billingAddress" type="radio" value={address.id} required 
                        onChange={addressChanged} checked={addressId === address.id}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded mb-2.5 md:mb-0"/>
                        <label htmlFor={`address-${address.id}`} className="text-xs text-slate-500 font-bold float-left text-left mx-2">
                          <span>
                            {address.firstName} {address.lastName}, {address.flatNo}, At: {address.village}, Tal: {address.taluka}, Dist: {address.dist}, Pincode: {address.pincode}, State: {getStateById(address.state)}, Mobile: {address.mobile}, Email: {address.email}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="flex">
                    <h2 className="font-bold text-base mt-2">
                      OR
                      <a href="/#/manage-address" 
                      className="transition ease-in duration-300 inline-flex items-center text-xs font-medium mx-2 mb-2 md:mb-0 bg-teal-500 px-2 py-1 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600"
                      type="submit"
                      >
                        <span>Add New Address</span>
                      </a>
                    </h2>
                  </div>
                  <div className="grid grid-cols-1 gap-5 mt-5">
                    <div className="flex items-center mb-4">
                      <input id="recheckAddress" type="checkbox" value="yes" required 
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded mb-2.5 md:mb-0"/>
                      <label htmlFor="recheckAddress" className="text-xs text-slate-500 font-bold float-left text-left mx-2">
                        <span className="text-red-500 mx-1">*</span>
                        मी संपूर्ण पत्ता पुन्हा एकदा पडताळून पाहिला आहे, आता माझी ऑर्डर द्यायला काही हरकत नाही.
                      </label>
                    </div>
                  </div>
                  <div className="my-4 w-full">
                    <button 
                    className="transition ease-in duration-300 inline-flex items-center text-sm font-medium mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600 "
                    type="submit"
                    >
                      <span>Pay ₹{book.spINR} & Buy</span>
                    </button>
                    <BackToHome title="Cancel"/>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
  )
}
export default Checkout;