import React, { useState } from "react";
import {useDispatch } from 'react-redux';
import {showToaster} from '../../../middleware/actions';
import BackToHome from '../back-to-home';
import {
  KeyIcon,
  PhoneIcon,
  CheckCircleIcon
} from '@heroicons/react/24/outline';
import CONST from '../../common/constants';
import Logo from "../../common/logo";
import axios from "axios";
import {showLoader} from '../../../middleware/actions';
function SetPassword({book, showModal, opts}) {
  const dispatch = useDispatch();
  const isLoggedIn = sessionStorage.getItem('isLoggedIn') === 'true';
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState({one:'',two:''});
  const [otpSent, setOtpSent] = useState(false);
  const [numVerified, setNumVerified] = useState(false);

  if(isLoggedIn){
    window.location.href = "/";
    return;
  }
  const passwordOneChanged = (event) => {
    const pswrds = {...password};
    pswrds.one = event.target.value;
    setPassword(pswrds);
  }
  
  const passwordTwoChanged = (event) => {
    const pswrds = {...password};
    pswrds.two = event.target.value;
    setPassword(pswrds);
  }
  const onNumberChange = (event)=> {
    setMobile(event.target.value);
  }
  const onOtpChange = (event)=> {
    setOtp(event.target.value);
  }
  const sendOtp = (event) => {
    dispatch(showLoader(true));
    event.preventDefault();
    axios
      .post(`${CONST.apiBase}login.php`, {
        type:'sendOtp',
        mobile: mobile
      })
      .then((response) => {
        dispatch(showLoader(false));

        if (response.data.status === "success") {
          setOtpSent(true);
        } else {
          opts.current.msg = response.data.msg;
          showModal(true);
        }
      })
      .catch((error) => {
        dispatch(showLoader(false));

        console.error(error);
      });
    // setTimeout(()=>{
    //   setOtpSent(false);
    // },3*1000)
  }
  const handleSubmit = (event) => {
    const obj = {type:'changePassword',mobile:mobile,password:password.one};
    dispatch(showLoader(true));
    event.preventDefault();
    axios
      .post(`${CONST.apiBase}login.php`, obj)
      .then((response) => {
        dispatch(showLoader(false));
        if (response.data.status === "success") {
            opts.current.title = 'Success';
            opts.current.msg = 'Password Changed Successfully and You are logged in with new password.';
            showModal(true);
            setTimeout(()=>{
              sessionStorage.setItem("isLoggedIn", true);
              sessionStorage.setItem(
                "userData",
                JSON.stringify(response.data)
              );
              const hasAddress = response?.data?.hasAddress;
              if(hasAddress!=='1'){
                window.location.href = "/#/user-account";
              }else{
                if(book && book.bookId){
                 window.location.href = "/#/checkout";
                }else{
                  window.location.href = "/";
                }
              }
            },3*1000);

        } else {
          opts.current.msg = response.data.msg;
          showModal(true);
        }
      })
      .catch((error) => {
        dispatch(showLoader(false));
        console.error(error);
      });
  }
  const verifyOTP = (event) => {
    dispatch(showLoader(true));
    event.preventDefault();
    axios
      .post(`${CONST.apiBase}login.php`, {
        type:'verifyOtp',
        mobile: mobile,
        otp: otp,
      })
      .then((response) => {
        dispatch(showLoader(false));
        if (response.data.status === "success") {
          setNumVerified(true);
          //dispatch(showToaster({show:true,type:'success',msg:'Mobile Number Verified Successfully.'}));
        } else {
          opts.current.msg = response.data.msg;
          showModal(true);
        }
      })
      .catch((error) => {
        dispatch(showLoader(false));
        console.error(error);
      });
  };
    return (
      <>
      <main className="flex items-center justify-center md:h-screen">
      <div className="relative mx-auto flex w-full max-w-[400px] flex-col space-y-2.5 p-4 md:-mt-32">
        <div className="flex h-32 w-full items-center  rounded-lg bg-teal-900 p-3">
          <div className="mx-auto"><Logo/></div>
        </div>
        <form className="space-y-3" name="loginForm" autocomplete="off" onSubmit={handleSubmit} >
          <div className="flex-1 rounded-lg bg-gray-50 px-6 pb-4 pt-8">
            <h1 className={`mb-3 text-2xl`}>
              Set New Password
            </h1>
            <div className="w-full">
              <div>
                <label
                  className="text-left mb-3 mt-5 block text-xs font-medium text-gray-900"
                  htmlFor="userPhone"
                >
                  Mobile Number
                </label>
                <div className="relative">
                  <input
                    className="peer block w-full rounded-md border border-gray-200 py-[9px] pl-10 text-sm outline-2 placeholder:text-gray-500 disabled:cursor-not-allowed"
                    id="userPhone"
                    type="tel"
                    name="userPhone"
                    placeholder="Enter 10 Digit Mobile Number"
                    onChange={onNumberChange}
                    pattern="[0-9]{10}"
                    minLength={10}
                    maxLength={10}
                    required
                    disabled={otpSent}
                  />
                  <PhoneIcon className="pointer-events-none absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />
                  {numVerified?(
                    <CheckCircleIcon className="pointer-events-none absolute right-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-green-600" title="Success"/>
                  ):null}
                </div>
              </div>
              <div className="mt-4">
                {(!numVerified && !otpSent)?(
                  <>
                    <BackToHome title="Cancel"/>
                    <button 
                    className="transition ease-in duration-300 inline-flex items-center text-sm font-medium mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600 disabled:bg-gray-500 disabled:cursor-not-allowed"
                    onClick={sendOtp}
                    disabled={mobile.length !== 10}
                    >
                      <span>Send OTP</span>
                    </button>
                    
                  </>
                ):null}
              </div>

              {(otpSent && !numVerified)? (
                <div className="mt-4">
                <label
                  className="text-left mb-3 block text-xs font-medium text-gray-900"
                  htmlFor="userOTP"
                >
                  OTP (Received over SMS)
                </label>
                <div className="relative">
                  <input
                    className="peer block w-full rounded-md border border-gray-200 py-[9px] pl-10 text-sm outline-2 placeholder:text-gray-500"
                    id="userOTP"
                    type="tel"
                    name="userOTP"
                    onChange={onOtpChange}
                    placeholder="Enter OTP"
                    required
                    pattern="[0-9]{4}"
                    minLength={4}
                    maxLength={4}
                    disabled={numVerified}
                  />
                  <KeyIcon className="pointer-events-none absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />
                </div>
              </div>
              ):null}
            </div>

            {(otpSent && !numVerified)? (
              <div className="mt-4">
                <BackToHome title="Cancel"/>
                <button 
                className="transition ease-in duration-300 inline-flex items-center text-sm font-medium mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600 "
                onClick={verifyOTP}
                >
                  <span>Verify OTP</span>
                </button>
              </div>
            ):null}
            {numVerified?(
              <>
                <div className="mt-4">
                  <label
                    className="text-left mb-3 block text-xs font-medium text-gray-900"
                    htmlFor="userPassword"
                  >
                    New Password (Minimum 8 Characters Long)
                  </label>
                  <div className="relative">
                    <input
                      className="peer block w-full rounded-md border border-gray-200 py-[9px] pl-10 text-sm outline-2 placeholder:text-gray-500"
                      id="userPassword"
                      type="password"
                      name="userPassword"
                      onChange={passwordOneChanged}
                      placeholder="Enter New Password"
                      required
                      minLength={8}
                    />
                    <KeyIcon className="pointer-events-none absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />
                  </div>
                </div>
                <div className="mt-4">
                  <label
                    className="text-left mb-3 block text-xs font-medium text-gray-900"
                    htmlFor="userNewPassword"
                  >
                    Re-Enter New Password
                  </label>
                  <div className="relative">
                    <input
                      className="peer block w-full rounded-md border border-gray-200 py-[9px] pl-10 text-sm outline-2 placeholder:text-gray-500"
                      id="userNewPassword"
                      type="password"
                      name="userNewPassword"
                      onChange={passwordTwoChanged}
                      placeholder="Re-Enter New Password"
                      required
                      minLength={8}
                    />
                    <KeyIcon className="pointer-events-none absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />
                  </div>
                </div>
              </>
            ):(null)}

            {numVerified? (
              <div className="mt-4">
                <BackToHome title="Cancel"/>
                <button 
                className="transition ease-in duration-300 inline-flex items-center text-sm font-medium mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600 disabled:bg-gray-500 disabled:cursor-not-allowed"
                onClick={handleSubmit}
                disabled={password.one.length < 8 || password.one !== password.two}
                >
                  <span>Set New Password</span>
                </button>
                
              </div>
            ):null}

          </div>
        </form>
      </div>
    </main>
    </>
    );
  }
  export default SetPassword