import { useState, useRef,useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { ArrowDownIcon } from '@heroicons/react/24/solid';
import CONST from './../../common/constants';
import getColumns from './columns';
import AddEditBook from './add-edit-book';
let catsCalled = false;
const Books = ({showLoader, showErrModal, opts}) => {
  const [books,setBooks] = useState([]);
  const [book,setBook] = useState({});
  const cats = useRef([]);
  const [showModal,setShowModal] = useState(false);
  const [columns,setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const editBook = (_book) => {
    setBook(_book);
    setShowModal(true);
  }
  const addNewBook = () => {
    const bookObj = {
      bookId:'',
      bookNameEng:'',
      bookDescEng:'',
      bookNameMar:'',
      bookDescMar:'',
      bookMRP:'',
      bookSP:'',
      bookQty:100,
      bookCategories:'',
      oldThumb:''
    };
    setBook(bookObj);
    setShowModal(true);
  }
  const fetchBooks = async (_page, _perPage) => {
    if(cats.current.length===0){
      return;
    }
    console.log('>>> cats:', cats);
    setLoading(true);
    showLoader(true);
    const response = await axios.post(`${CONST.apiBase}dashboard.php`,{
      type:'getPagewiseBooks',
      page:_page,
      perPage:_perPage
    });
    setLoading(false);
    showLoader(false);
    if(response.data.status === 'success'){
      setBooks(response.data.books);
      setColumns(getColumns(response.data.books,editBook,showLoader, showErrModal, opts))
      setTotalRows(response.data.totalBooks);
    }else{
      opts.current.msg = response?.data?.msg;
      showErrModal(true);
    }
  };
  
  const handlePageChange = page => {
    fetchBooks(page, perPage);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    fetchBooks(page,newPerPage);
  };
  useEffect(() => {
    (async () => {
      if(!catsCalled){
        catsCalled = true;
        showLoader(true);
        const response = await axios.post(`${CONST.apiBase}dashboard.php`,{
          type:'getCategories'
        });
        showLoader(false);
        if(response.data.status === 'success'){
          cats.current = response.data.rows;
          fetchBooks(1, perPage);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
    <div className="py-2 float-right">
        <button 
            className="transition ease-in duration-300 inline-flex items-center text-sm font-medium mb-2 md:mb-0 bg-teal-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600 "
            type="button"
            onClick={addNewBook}
            >
            <span>+ Add New Book</span>
        </button>
    </div>
      <DataTable
          title="Books"
          columns={columns}
          data={books}
          defaultSortFieldId={1}
          sortIcon={<ArrowDownIcon />}
          progressPending={loading} 
          pagination 
          paginationServer 
          paginationTotalRows={totalRows} 
          onChangeRowsPerPage={handlePerRowsChange} 
          onChangePage={handlePageChange}
      />
      {showModal?(<AddEditBook book={book} cats={cats} setShowModal = {setShowModal} showLoader={showLoader} showErrModal={showErrModal} opts={opts}/>):null}
    </>
  )
};
export default Books;