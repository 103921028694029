// actions.js
export const SHOW_lOADER = 'SHOW_lOADER';
export const SHOW_TOASTER = 'SHOW_TOASTER';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const DECREASE_QTY = 'DECREASE_QTY';
export const INCREASE_QTY = 'INCREASE_QTY';
export const SEARCH_TEXT = 'SEARCH_TEXT';
export const CLEAR_SEARCH_TEXT = 'CLEAR_SEARCH_TEXT';

export const showLoader = (loader) => ({
  type: SHOW_lOADER,
  payload: loader,
});
export const showToaster = (toaster) => ({
  type: SHOW_TOASTER,
  payload: toaster,
});
export const addToCart = (cart) => ({
  type: ADD_TO_CART,
  payload: cart,
});
export const removeFromCart = (cart) => ({
  type: REMOVE_FROM_CART,
  payload: cart,
});
export const increaseQty = (cart) => ({
  type: INCREASE_QTY,
  payload: cart,
});
export const decreaseQty = (cart) => ({
  type: DECREASE_QTY,
  payload: cart,
});
export const setSearchText = (text) => ({
  type: SEARCH_TEXT,
  payload: text,
});
export const clearSearchText = (text) => ({
  type: SEARCH_TEXT,
  payload: text,
});