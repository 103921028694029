import { useDispatch } from 'react-redux';
import {CurrencyRupeeIcon, EyeIcon, ShoppingCartIcon} from '@heroicons/react/24/solid';
import { showLoader } from '../../../../middleware/actions';
import { addToCart } from '../../../../middleware/actions';
import { calcDiscount } from '../../../common/utils';
import {gotoCheckoutPage, gotoMyCartPage} from '../../../../helpers/helpers';
const Book = ({book, setSelBook}) => {
  const dispatch = useDispatch();
    const checkout = () => {
      //setSelBook(book);
      // dispatch(showLoader());
      //gotoCheckoutPage();
      dispatch(addToCart(book));
      gotoMyCartPage();
    }
    const goToBookDetails = () => {
      setSelBook(book);
      let bookName = book.bookNameEng.toLowerCase().replaceAll(' ','-');
      window.location.href = `/#/book-details/${bookName}-${book.bookId}`;
    }
    const addToCartClicked = () => {
      dispatch(addToCart(book));
    }
    return (
      <div className="relative flex flex-col items-center p-4 md:p-2 m-auto lg:m-2 md:w-80 w-96">
        <div className="container">
          <div className="max-w-md w-full bg-teal-700 shadow-lg rounded-md p-1">
            <div className="grid grid-cols-5 gap-1">
              <div className="col-span-2">
                <div className="relative h-62 w-28">
                  <div className="badge w-8 h-8 bg-red-600 text-red-100 absolute rounded-full flex items-center justify-center font-bold z-10" style={{fontSize:'small',left:'4px', top:'4px'}}>-{calcDiscount(book.mrpINR,book.spINR)}%</div>
                  <img 
                    src={`book-thumbnails/${book.img}`} 
                    alt={book.bookNameEng} className="w-28 h-36 object-fill  rounded-md"
                    onClick={goToBookDetails}
                    />
                </div>
              </div>
              <div className="col-span-3">
                <div className="flex-auto w-44 justify-evenly">
                  <div className="flex flex-wrap h-16 p-2">
                    <div className="flex w-full justify-between min-w-0">
                      <h2 className="text-sm text-left text-gray-200 font-bold w-full truncate" title={book.bookNameEng}>{book.bookNameEng}</h2>
                    </div>
                    <div className="flex w-full justify-between min-w-0">
                      <h2 className="text-sm text-left text-gray-200 font-bold w-full truncate" title={book.bookNameMar}>{book.bookNameMar}</h2>
                    </div>
                  </div>
                  <div className="text-white m-2">
                    <span className="font-semibold">
                      ₹{book.spINR}
                    </span>
                    <span className="ml-3 line-through font-thin text-base">
                    ₹{book.mrpINR}
                    </span>
                  </div>
                    <div className="flex space-x-2 text-xs font-medium justify-center p-1">
                      <button 
                      className="transition ease-in duration-300 inline-flex items-center font-medium bg-purple-500 px-1 py-0.5 hover:shadow-lg tracking-wider text-white  rounded-full hover:bg-purple-600"
                      onClick={checkout}
                      >
                        <CurrencyRupeeIcon width={16} height={16} style={{marginRight:'2px'}}/>
                        <span>Buy</span>
                      </button>
                      <button 
                      className="transition ease-in duration-300 inline-flex items-center font-medium bg-purple-500 px-1 py-0.5 hover:shadow-lg tracking-wider text-white  rounded-full hover:bg-purple-600 "
                      onClick={goToBookDetails}
                      >
                        <EyeIcon width={16} height={16} style={{marginRight:'2px'}}/>
                        <span>View</span>
                      </button>
                      <button 
                      className="transition ease-in duration-300 inline-flex items-center font-medium bg-purple-500 px-1 py-0.5 hover:shadow-lg tracking-wider text-white  rounded-full hover:bg-purple-600 "
                      onClick={addToCartClicked}
                      >
                        <span>+</span>
                        <ShoppingCartIcon width={16} height={16} style={{marginRight:'2px'}}/> 
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
export default Book;