import { useState,useEffect } from "react";
const Modal = ({title='Error Occured',msg='An Error Occured',showModal = ()=>{},modal=false}) => {
    const [top,setTop] = useState('50px');
    const closeModal = () => {
        showModal();
    }
    useEffect(()=>{
        const winH = window.innerHeight;
        const scrollY = window.scrollY;
        setTop((scrollY+(winH/2)));
    },[modal]);
    return (
        <>  
            <div className="w-full h-full">
                <div id="modal-bg" onClick={closeModal} 
                className="flex items-center justify-center space-x-2 fixed inset-0 z-50 bg-gray-900 bg-opacity-60"></div>
                <div id="modal-box" 
                className="bg-teal-100 w-[320px] min-w-80] min-h-48 flex flex-col items-center gap-2 -translate-y-1/2 px-6 py-2 rounded-lg top-1/2 left-1/2 -translate-x-1/2 absolute z-50" style={{top:top}}>
                    <span className="text-2xl font-medium">{title}</span>
                    <p className="text-center">{msg}</p>
                    <button onClick={closeModal} className="absolute inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white bg-teal-900 hover:border-transparent hover:bg-teal-700 bottom-4">Close</button>
                </div>
            </div>
        </>   
    )
}
export default Modal;