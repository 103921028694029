const BackToHome = ({title='Back'}) => {
    const goToHomePage = () => {
        window.location.href = '/';
    }
    return (
        <button 
        className="transition ease-in duration-300 inline-flex items-center text-sm font-medium mb-2 md:mb-0 bg-teal-500 mx-2 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-teal-600 "
        type="button"
        onClick={goToHomePage}
        >
          <span>{title}</span>
        </button>
    );
}
export default BackToHome;