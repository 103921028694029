import CONST from '../common/constants';
import axios from "axios";
export const getBooks = (setBooks, showLoader)=> {
    showLoader(true);
    axios
    .post(`${CONST.apiBase}data.php`, {
      type: 'books',
      catId: 'all',
    })
    .then((response) => {
      showLoader(false);
      if(response?.data?.books){
        setBooks(response.data.books);
        return response;
      }else{
        console.error('>>err:');
      }
    })
    .catch((error) => {
      showLoader(false);
      console.error('>>err:',error);
    });
}
export const calcDiscount = (mrp,sp) => {
  return parseInt(((mrp-sp)/mrp)*100);
}
export const getDateTime = (timestamp, localTime) => {
  const dateToProcess = (localTime && localTime!=='0')?parseInt(localTime):timestamp;
    const dt = new Date(dateToProcess);
    let mm = (dt.getMonth()+1);
    mm = mm<10? ('0'+mm):mm;
    const outDate = dt.getDate()+'/'+mm+'/'+dt.getFullYear();

    let hrs = dt.getHours();
    let mins = dt.getMinutes();
    mins = mins<10?('0'+mins):mins;
    const ampm = hrs>=12?'PM':'AM';
    if(hrs>12){
      hrs -= 12;
    }
    const outTime = hrs+':'+mins+ampm;
    
    return outDate+' '+outTime;
}